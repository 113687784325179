export const personalFieldsObj = {
  nickname: {
    order: 1,
    element: "input",
    type: "text",
    label: "Nickname",
    enabled: true,
    initialValue: undefined,
    value: undefined,
    id: "nickname",
  },
  birthday: {
    order: 2,
    element: "input",
    type: "date",
    label: "Birthday",
    enabled: true,
    initialValue: undefined,
    value: undefined,
    id: "date"
  },
  gender: {
    order: 3,
    element: "select",
    type: "",
    label: "Gender",
    enabled: true,
    initialValue: undefined,
    value: undefined,
    id: "gender_select"
  },
  personalBio: {
    order: 4,
    element: "textarea",
    type: "",
    label: "Personal Bio",
    enabled: true,
    initialValue: undefined,
    value: undefined,
    id: "personal_bio"
  },
}
