import { getEnvironment } from "./ar_globals.js";
import { roundArrow } from "tippy.js";

window.isDesktop = window.matchMedia("(min-width: 768px)").matches;
window.deviceTrigger = window.isDesktop ? "mouseenter" : "click";
window.deviceTriggerEnv =
  getEnvironment() === "Production" ? window.deviceTrigger : "click";

// console.log('getEnvironment', getEnvironment())
// console.log('window.deviceTriggerEnv', window.deviceTriggerEnv);

const appwideTippySettings = {
  delay: 0,
  interactive: true,
  arrow: roundArrow,
  trigger: window.deviceTriggerEnv,
  inertia: true,
};

export function initialiseTippyOn(params) {
  const selector = params.element ?? document.querySelectorAll(params.selector);
  // console.log('Tippy selector:', selector)

  const defaultOnCreate = (instance) => {
    instance.reference.addEventListener('click', (event) => {
      event.stopPropagation();
    });
  };

  const defaultOnMount = (instance) => {
    instance.popper.addEventListener('click', (event) => {
      event.stopPropagation();
    });
  };

  return tippy(selector, {
    ...appwideTippySettings,
    theme: params.theme,
    placement: params.placement || "top",
    allowHTML: params.allowHTML || false,
    content: params.content,
    offset: params.offset,
    onShow: params.onShow,
    onHidden: params.onHidden,
    hideOnClick: params.hideOnClick || true,
    animation: params.animation || "scale",
    duration: params.duration || [100, 100],
    onCreate: (instance) => {
      defaultOnCreate(instance);
      if (params.onCreate) {
        params.onCreate(instance);
      }
    },
    onMount: (instance) => {
      defaultOnMount(instance);
      if (params.onMount) {
        params.onMount(instance);
      }
    }
  });
}
window.initialiseTippyOn = initialiseTippyOn
