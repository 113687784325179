import { apiURL } from "./restapi_requests";
import { userDataObj } from "../ui_general/ar_globals";

export async function registerTheUser(email, password) {
  console.log('registerTheUser()');

  const response = await fetch(apiURL('ar', 'users/register'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    })
  });

  const result = await response.json();

  console.log('result', result)

  if (response.ok) {
    console.log('response ok');

    return result
  } else {
    throw new Error(result.message);
  }
}
