import { restAPIRequest } from "../restapi/restapi_requests";
import { createSPCRow } from "../ui_components/spc_row";
import { linkToSPCPaneBlock } from "../blocks/spcViewBlock.js";
import { icons } from "../ui_general/icons.js";

export const sectionCourses = document.createElement("section");
sectionCourses.setAttribute("id", "courses");
sectionCourses.classList.add("membership", "hidden");
sectionCourses.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon='${icons.postType.courses}'></iconify-icon>
        <div class='label'>Self-Paced Courses</div>
      </div>
    </div>

    <div class="contents">

      <div id="spc_main" class="block">

        <div id="spc_list" class="spc_list">
        </div>

        <div id="spc_list_prev_next_wrap" class='pagination_wrap'>
          <button-arrow direction="left" section="spc"></button-arrow>
          <div class="pageCount">
            <div class="count_widget currentPage"></div>/
            <div class="count_widget totalPages"></div>
          </div>
          <button-arrow direction="right" section="spc"></button-arrow>
        </div>

      </div>

    </div>
  </div>
  `;


export const spcLibraryState = {
  page: 1,
  isLoading: false,
  totalPages: undefined,
}

export async function uiGetSPCPosts(
  {
    page = spcLibraryState.page,
    postType = "spc",
    postsPerPage = 8,
  } = {}) {

  const config = { postType, page, postsPerPage };

  const spcMain = sectionCourses.querySelector("div#spc_main");
  const spcList = sectionCourses.querySelector("div#spc_list");
  const spcPagination = sectionCourses.querySelector("div.pagination_wrap");
  const loadSpinner = document.createElement("loading-spinner");

  if (!spcMain.querySelector("loading-spinner")) {
    spcMain.appendChild(loadSpinner);
  }
  spcList.classList.add("global_blur");
  spcPagination.classList.add("global_blur");

  return restAPIRequest(config)
    .then((data) => {
      spcLibraryState.page = data.page;
      spcLibraryState.totalPages = data.totalPages;

      processSPCPosts(data);

      document.dispatchEvent(
        new CustomEvent('restAPILoadComplete', {
          bubbles: true,
          composed: true,
          detail: {
            page: data.page,
            totalPages: data.totalPages,
            section: "spc",
          }
        })
      );
    })
    .finally(() => {
      const loadingSpinner = spcMain.querySelector("loading-spinner");

      if (loadingSpinner) {
        spcMain.removeChild(loadingSpinner);
      }

      spcList.classList.remove("global_blur");
      spcPagination.classList.remove("global_blur");
    })
    .catch((error) => {
      console.error('uiGetSPCPosts()', error);
    })
}

export function processSPCPosts(
  {
    posts: spcPosts,
    target: target,
    totalPages: totalPages,
    page: page } = {}) {

  const spcList = sectionCourses.querySelector("div#spc_list");
  if (target === undefined) target = spcList;

  // Update pages count widgets
  document.querySelector("#spc_list_prev_next_wrap div.totalPages").innerText = totalPages || "";
  document.querySelector("#spc_list_prev_next_wrap div.currentPage").innerText = page || "";

  if (spcPosts) {
    target.innerHTML = "";
    spcPosts.forEach((spcPost) => {
      target.appendChild(createSPCRow(spcPost));
    });
  }
}


document.addEventListener("spc_load_event", async (event) => {
  if (spcLibraryState.isLoading) return;

  const { direction } = event.detail;

  spcLibraryState.isLoading = true;

  if (direction === "left" && spcLibraryState.page > 1) {
    spcLibraryState.page -= 1;
  } else if (direction === "right") {
    spcLibraryState.page += 1;
  }

  try {
    // Invoke the network request and wait for it to complete
    await uiGetSPCPosts({
      page: spcLibraryState.page
    });

  } catch (error) {
    console.error("Failed to fetch SPC posts:", error);
  } finally {
    console.log('dispatch', spcLibraryState)
    const loadCompleteEvent = new CustomEvent('restAPILoadComplete', {
      bubbles: true,
      composed: true,
      detail: {
        page: spcLibraryState.page,
        totalPages: spcLibraryState.totalPages,
        section: "spc"
      }
    });

    document.dispatchEvent(loadCompleteEvent);
    spcLibraryState.isLoading = false;
  }

})

document.addEventListener('loginEvent', function (event) {
  uiGetSPCPosts();
});

document.addEventListener("logoutEvent", () => {
  uiGetSPCPosts();
})

// // Array
// 1. Module title
// 2. Files
//  - `acf_fc_layout`: lecture | meditation | document

// Lecture:
// - title (text)
// - format (select: video | audio | video_audio)
// - duration (number)
//
// Meditation:
// - postID (fetch it via new Meditation())
//
// Document:
// - title (text)
// - format (select: PDF | graphic)
