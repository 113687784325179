import { apiURL } from "./restapi_requests";
import { userDataObj } from "../ui_general/ar_globals.js";

document.addEventListener("DOMContentLoaded", () => {

  // [] create a login status widget
  // [] give it states
  // [] give it setters so other code can update it
});

// Functions
export async function logTheUserIn(email, password) {
  // try {
  // console.log('logTheUserIn() attempt:', email, password);

  const loginResponse = await getLoginToken(email, password);

  const tokenResponse = await getLoginAuth(loginResponse.token);

  // console.log('logTheUserIn() Token data:', tokenResponse);

  if (!tokenResponse.data.status === 200) {
    throw new Error('Login failed with status: ' + tokenResponse.data.status);
  }

  return tokenResponse;

  async function getLoginToken(username, password) {
    const response = await fetch(apiURL("jwt", "token"), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      })
    });

    const result = await response.json();

    if (response.ok) {
      // console.log('getLoginToken() ok');

      if (result.token) {
        // console.log('-- JWT Token: ', result.token)
        localStorage.setItem("AR_Userdata_JWT", result.token);
      }

      return result;
    } else {
      throw new Error(result.message);
    }
  };
}

export function checkUserLoginStatus() {
  // Every login function goes through here, so it can be used to trigger all essential
  // side effects
  return new Promise((resolve) => {

    // parameter acf_format=standard returns both value and label, instead of just value.
    fetch(`${apiURL("wp", "users/me")}?acf_format=standard`, {
      headers: {
        "Authorization": authHeader(),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Login response was not ok.");
      })
      .then((data) => {
        // console.log('checkUserLoginStatus() response data:', data);

        const ARUserAccount = {
          name: data.name,
          id: data.id,
          slug: data.slug,
          is_super_admin: data.is_super_admin,
          membership_level: data.acf.membership_level,
          isLoggedIn: true,
        };

        // Set global data
        userDataObj.account = ARUserAccount;
        document.body.dataset["loggedIn"] = "true";
        console.log('userDataObj', userDataObj);

        // Save for offline
        localStorage.setItem("AR_Userdata_Account", JSON.stringify(ARUserAccount));

        resolve(true);
        // User is logged in, you can add further actions here
      })
      .catch((error) => {
        console.error(
          "checkUserLoginStatus() fetch:",
          error,
        );
        resolve(false);
        // User is not logged in, you can add further actions here
      });
  });
}

export async function validateLocalJWT(localStorageJWT = null) {
  if (!localStorageJWT) return false;

  // Validate token
  const tokenResponse = await getLoginAuth(localStorageJWT);

  if (!tokenResponse.data.status === 200) {
    throw new Error('Login failed with status: ' + tokenResponse.data.status);
  }

  // console.log('validateLocalJWT(): 200 OK');
  return tokenResponse;
}

export async function getLoginAuth(token) {
  try {
    const response = await fetch(apiURL("jwt", "token/validate"), {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    if (response.ok) {
      checkUserLoginStatus()
        .then(isLoggedIn => {
          console.log('getLoginAuth(): 🚪 loginEvent')
          const loginEvent = new CustomEvent('loginEvent', { detail: { isLoggedIn } });
          document.dispatchEvent(loginEvent);
        }).finally(() => {
          // console.log('...');
        }).catch(error => {
          console.error('getLoginAuth():', error);
        });
      return await response.json();
    } else {
      throw new Error('getLoginAuth(): Failed to fetch protected data');
    }
  } catch (error) {
    console.error('getLoginAuth():', error);
  }
};

export function authHeader() {
  const AR_JWT = localStorage.getItem("AR_Userdata_JWT");
  const authHeader = AR_JWT
    ? `Bearer ${AR_JWT}`
    : "";
  // console.log('AR_JWT', AR_JWT, 'authHeader', authHeader);

  return authHeader
}

// [] Check this restGetWPUser(), as it's currently unused

export function restGetWPUser() {

  return new Promise((resolve) => {

    fetch(apiURL("wp", "users/me"), {
      headers: {
        "Authorization": authHeader(),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Login response was not ok.");
      })
      .then((data) => {
        // console.log('checkUserLoginStatus()', data);
        const ARUserAccount = {
          name: data.name,
          id: data.id,
          slug: data.slug,
          is_super_admin: data.is_super_admin,
          // acf: data.acf
        };

        localStorage.setItem("AR_Userdata_Account", JSON.stringify(ARUserAccount));

        resolve(true);
        // User is logged in, you can add further actions here
      })
      .catch((error) => {
        console.error(
          "checkUserLoginStatus() fetch:",
          error,
        );
        resolve(false);
        // User is not logged in, you can add further actions here
      });
  });
}
