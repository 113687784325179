export const terms_techniques = [
  {
    "name": "Buddhist Heart Practices",
    "slug": "buddhist-heart-practices",
    "count": 75,
    "description": "These meditation practices are derived from Buddhism, where they are known as the four \"Brahma Viharas\", which roughly means \"Sublime Emotions\", or \"Heavenly dwellings\" — Loving kindness, compassion, sympathetic joy and equanimity. ",
    "id": 88,
    "parent": 172
  },
  {
    "name": "Chairwork",
    "slug": "chairwork",
    "count": 54,
    "description": "In Chairwork we have a dialog between multiple parts of the psyche, which can lead to gaining new understanding and clarity around your Schemas and Parts.",
    "id": 121,
    "parent": 174
  },
  {
    "name": "Conference Room Technique",
    "slug": "conference-room-technique",
    "count": 5,
    "description": "",
    "id": 123,
    "parent": 121
  },
  {
    "name": "Confrontation Scene",
    "slug": "confrontation-scene",
    "count": 18,
    "description": "",
    "id": 87,
    "parent": 0
  },
  {
    "name": "Confronting the Critical Voice",
    "slug": "confronting-critical-voice",
    "count": 7,
    "description": "This meditation focuses on challenges introjects, criticisms of ourselves we learned by hearing them from others during childhood. We seek to understand and disconfirm the voices that Schema Therapy identifies as demanding parent, punitive parent, and guilt-inducing parent.",
    "id": 128,
    "parent": 174
  },
  {
    "name": "Development of Best Self",
    "slug": "development-of-best-self",
    "count": 2,
    "description": "",
    "id": 176,
    "parent": 0
  },
  {
    "name": "Ego Strength Meditation",
    "slug": "ego-strength-meditation",
    "count": 41,
    "description": "",
    "id": 258,
    "parent": 0
  },
  {
    "name": "Embodying Secure Attachment",
    "slug": "embodying-secure-attachment",
    "count": 22,
    "description": "",
    "id": 185,
    "parent": 0
  },
  {
    "name": "Equanimity",
    "slug": "equanimity",
    "count": 17,
    "description": "",
    "id": 80,
    "parent": 88
  },
  {
    "name": "Focused Healing",
    "slug": "focused-healing",
    "count": 1,
    "description": "The deep inner work of creating emotionally corrective experiences, re-consolidating troubling memories, and repatterning the Schemas, Parts, and insecure attachment factors.",
    "id": 174,
    "parent": 0
  },
  {
    "name": "Forgiveness",
    "slug": "forgiveness",
    "count": 16,
    "description": "",
    "id": 108,
    "parent": 174
  },
  {
    "name": "Grief Protocol",
    "slug": "grief-protocol",
    "count": 9,
    "description": "",
    "id": 260,
    "parent": 0
  },
  {
    "name": "Healthy Adult",
    "slug": "healthy-adult",
    "count": 40,
    "description": "",
    "id": 259,
    "parent": 0
  },
  {
    "name": "Ideal Parent Figure Protocol",
    "slug": "ideal-parent-figure-protocol",
    "count": 11,
    "description": "",
    "id": 275,
    "parent": 0
  },
  {
    "name": "Interpersonal Episode Resolution",
    "slug": "interpersonal-episode-resolution",
    "count": 3,
    "description": "",
    "id": 263,
    "parent": 0
  },
  {
    "name": "Investigating Attachment",
    "slug": "investigating-attachment",
    "count": 45,
    "description": "",
    "id": 159,
    "parent": 173
  },
  {
    "name": "Me Too",
    "slug": "me-too",
    "count": 4,
    "description": "",
    "id": 127,
    "parent": 0
  },
  {
    "name": "Mentalizing the Modes",
    "slug": "mentalizing-the-modes-metacognition",
    "count": 3,
    "description": "",
    "id": 270,
    "parent": 173
  },
  {
    "name": "Mentalizing the Modes Back to the Schema",
    "slug": "mentalizing-the-modes",
    "count": 84,
    "description": "In this meditation we develop understanding of our different Modes (or Parts) and behaviours from a non-pathologizing, pro-symptom position and then identify deeply-held emotional beliefs that make them necessary. It ends with emotionally corrective experiences to bring about memory-reconsolidation.",
    "id": 91,
    "parent": 173
  },
  {
    "name": "Metacognition",
    "slug": "metacognition",
    "count": 7,
    "description": "Refers to the ability to watch your thoughts as they happen, to see your mental activity in real-time in order to understand it.",
    "id": 173,
    "parent": 0
  },
  {
    "name": "Metacognition of Mental States",
    "slug": "metacognition-of-mental-states",
    "count": 52,
    "description": "",
    "id": 122,
    "parent": 173
  },
  {
    "name": "Metta",
    "slug": "metta",
    "count": 4,
    "description": "",
    "id": 262,
    "parent": 88
  },
  {
    "name": "Mindfulness of the Modes, One by One",
    "slug": "mindfulness-of-the-modes",
    "count": 39,
    "description": "",
    "id": 75,
    "parent": 173
  },
  {
    "name": "Perfect Nurturer Reinforcement",
    "slug": "perfect-nurturer-reinforcement",
    "count": 185,
    "description": "This is a foundational Attachment Repair meditation. We give a lived experience of the factors that bring about secure attachment. The Perfect Nurturers are imaginal parent-like avatars that reparent us.",
    "id": 124,
    "parent": 174
  },
  {
    "name": "Processing Threat and Danger",
    "slug": "processing-threat-and-danger",
    "count": 4,
    "description": "",
    "id": 269,
    "parent": 0
  },
  {
    "name": "Safe Space",
    "slug": "safe-space",
    "count": 5,
    "description": "",
    "id": 120,
    "parent": 172
  },
  {
    "name": "Schema Repatterning Meditation",
    "slug": "schema-repatterning-meditation",
    "count": 212,
    "description": "In this meditation we identify deeply held negative emotional beliefs (Schemas), and produce an emotionally corrective experience to heal it, usually with a Perfect Nurturer meditation.",
    "id": 93,
    "parent": 174
  },
  {
    "name": "Soothing",
    "slug": "soothing",
    "count": 0,
    "description": "It's important to emotionally regulate the mind into a clear state from which you can do deeper healing work.",
    "id": 172,
    "parent": 0
  },
  {
    "name": "Sympathetic Joy",
    "slug": "sympathetic-joy",
    "count": 41,
    "description": "Sympathetic Joy, also called \"mudita\" in Sanskrit, describes an attitude of taking joy in the good qualities of others. It is the opposite of envy. This practice invites you to experience delight in others, which is a quality of the perfect nurturers, used in other Attachment Repair meditations.",
    "id": 79,
    "parent": 88
  },
  {
    "name": "Tong Len",
    "slug": "tong-len",
    "count": 64,
    "description": "Tong-len is a practice for developing compassion. Compassion has the qualities of attunement and soothing towards your own or other's suffering. It includes a recognition of shared humanity and the desire that the suffering decreases.",
    "id": 107,
    "parent": 88
  }
];
