import { accountForm } from "../ui_components/account_form.js";

export const sectionStart = document.createElement("section");
sectionStart.setAttribute("id", "home");
sectionStart.classList.add("membership");
sectionStart.innerHTML = `
  <div class="container">

    <div class='contents'>
      <div id="start_header">
        <img id="arLogo" src="svg/logo128.svg" />
        <div id='main_header'>Attachment Repair Membership</div>
      </div>

      <div id="account_entry_form_container" class="block_container">

      </div>

      <div class="block_container" id="install_app">
        <h2>Install to Device</h2>
        <div id='install_button' class='button'>📲 Install App</div>
      </div>

      <div class="block_container" id="resume_block">
        <h2>Resume</h2>
        <p>...</p>
      </div>

      <div class="block_container" id="new_content_block">
        <h2>New Content</h2>
        <p style='display: none'>Display latest 3 SPCs and latest 5 meditations</p>
      </div>

      <div class="block_container" id="changelog_block">
        <h2>Updates</h2>

        <div id="changelog_content">

          <h3>Fri 20, September 2024</h3>
          <p>You can now write journal entries in each meditation, to record any thoughts or insights. They are stored locally on your device (syncing to account isn't yet active).</p>

          <h3>Hello</h3>
          <p>Welcome to the AR Membership beta.</p>
        </div>
      </div>

      <div class="block_container" id="feedback_block">
        <h2>Feedback</h2>
        <p>Send us anonymous feedback</p>
      </div>

    </div>
  </div>
  `;

document.addEventListener("DOMContentLoaded", () => {

  sectionStart.querySelector("div#account_entry_form_container").insertAdjacentElement('afterbegin', accountForm)
});
