export const themeSelector = document.createElement("select");
themeSelector.setAttribute("id", "theme_switcher");
themeSelector.innerHTML = `
  <select id='theme_switcher'>
    <option selected disabled>Select</option>
    <option>Light Mode</option>
    <option>Dark Mode</option>
    <option>System Setting</option>
  </select>`;

// Theme strings: "Light Mode" | "Dark Mode" | "System Setting"

document.addEventListener("DOMContentLoaded", setupThemeSwitcher)

export function setupThemeSwitcher() {
  // console.log('setupThemeSwitcher() run');

  // <select> theme switcher
  const themeSelector = document.querySelector("#theme_switcher");

  // Load the theme from local storage
  const savedTheme = localStorage.getItem("AR_Userdata_Settings_theme") || "Light Mode";
  setTheme(savedTheme);

  if (themeSelector) {
    themeSelector.addEventListener("change", (event) => {
      setTheme(event.target.value);
    });
  }

  function setTheme(theme) {
    const theme_slug = theme.toLowerCase().replace(/\s+/g, "-");

    // This is what the CSS uses
    document.body.setAttribute("data-theme", theme_slug);

    // Update value on the switcher just for user visibility
    if (themeSelector) {
      themeSelector.value = theme;
    }

    // Update local storage
    localStorage.setItem("AR_Userdata_Settings_theme", theme);
  }
}
