import { icons } from "./icons";
import { arURL } from "./ar_globals";
import { settingsBlock, settingsBlockCallback } from "../blocks/settingsBlock";
import { billingBlock, billingBlockCallback } from "../blocks/billingBlock";
import { personalBlock, personalBlockCallback, personalBlockClose } from "../blocks/personalBlock";
import { assessmentsBlock, assessmentsBlockCallback } from "../blocks/assessmentsBlock";
import { meditationUserData } from "../data/ar_globaldata";
import { animateDeletingJournal, deleteJournalData } from "../journal/journal_entries";


/**
  type:
  - 'info_item'
  - 'toggle_item'
  - 'confirm_action'
  - 'single_action'
  - 'sub_menu'
**/

export function meditationMenu(dataset) {
  const menu = {
    dataset: dataset,
    functions: {
      addFav: (event, dataset) => {
        event.currentTarget.classList.add("on");
        meditationUserData.addFavourite({
          meditationId: dataset.meditationId,
          dataset: dataset,
        })
      },
      removeFav: (event, dataset) => {
        event.currentTarget.classList.remove("on");
        meditationUserData.removeFavourite({
          meditationId: dataset.meditationId,
          dataset: dataset,
        })
      },
    },
    menu_items: [
      {
        enabled: true,
        label: `MeditationId: ${dataset.meditationId}`,
        icon: "iconamoon:apps-fill",
        type: "info_item",
      },
      {
        enabled: true,
        label: "Playcount",
        icon: "mingcute:play-fill",
        type: "info_item",
      },
      {
        enabled: true,
        label: "Downloaded",
        icon: "iconamoon:cloud",
        type: "toggle_item",
      },
      {
        enabled: true,
        label: "Favourite",
        icon: icons.fav,
        type: "toggle_item",
        function: {
          on: (event, dataset) => menu.functions.addFav(event, dataset),
          off: (event, dataset) => menu.functions.removeFav(event, dataset)
        },
        actionType: "toggle",
        id: dataset.meditationId,
        data: meditationUserData,
      },
      {
        enabled: true,
        label: "Mark Complete",
        icon: icons.checkOff,
        type: "toggle_item",
      },
      {
        label: "Share Link",
        icon: "iconamoon:share-2-bold",
        type: "toggle_item",
      },
      {
        label: "Journal",
        icon: "ooui:journal-ltr",
      },
      {
        label: "Chats",
      },
    ]
  };
  return menu;
};

export function deleteMenu(uuid, tippyInstance) {
  const menu = {
    dataset: uuid,
    functions: {
      close: () => {
        if (tippyInstance) {
          tippyInstance.hide();
        }
      },
      delete: (uuid) => {
        /**
          receive journal UUID.
          each journal could be a class, then we could just call the delete method on it.
        **/

        animateDeletingJournal(uuid);
        deleteJournalData(uuid);
        menu.functions.close();
      },
    },
    menu_items: [
      {
        enabled: true,
        active: true,
        order: 1,
        label: "Delete",
        icon: icons.bin,
        type: "action",
        size: "normal",
        function: {
          action: (uuid) => menu.functions.delete(uuid),
        }
      },
      {
        enabled: false,
        active: true,
        order: 2,
        label: "Cancel",
        icon: icons.close,
        type: 'close_menu',
        function: {
          action: () => menu.functions.close(),
        }
      }
    ]
  };

  return menu;
}

export const profileMenuItems = {
  personal: {
    enabled: true,
    active: true,
    order: 1,
    label: "Personal",
    icon: icons.profile,
    content: personalBlock,
    type: "nav",
    size: "normal",
    callback: {
      open: personalBlockCallback,
      close: personalBlockClose
    },
  },
  assessments: {
    enabled: true,
    active: false,
    order: 2,
    label: "Assessments",
    icon: icons.assessments,
    content: assessmentsBlock,
    type: "nav",
    size: "normal",
    callback: { open: assessmentsBlockCallback },
  },
  membership: {
    enabled: true,
    active: true,
    order: 3,
    label: "Membership",
    icon: icons.membership,
    content: billingBlock,
    type: "nav",
    size: "normal",
    callback: { open: billingBlockCallback },
  },
  settings: {
    enabled: true,
    active: false,
    order: 4,
    label: "Settings",
    icon: icons.settings,
    content: settingsBlock,
    type: "nav",
    size: "normal",
    callback: { open: settingsBlockCallback },
  }
}

export const socialMenuItems = {
  newsletter: {
    enabled: false,
    active: true,
    order: 1,
    label: "Newsletter",
    icon: icons.newsletter,
    content: "",
    type: "link",
    size: "small",
    url: `https://attachmentrepair.com/newsletter?utm_source='${arURL.utm_source}'`,
  },
  tiktok: {
    enabled: true,
    active: true,
    order: 2,
    label: "Tiktok",
    icon: icons.tiktok,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.tiktok.com/@attachmentrepair/?utm_source='${arURL.utm_source}'`,
  },
  youtube: {
    enabled: true,
    active: true,
    order: 3,
    label: "Youtube",
    icon: icons.youtube,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.youtube.com/channel/UCcBp5ec4Bz7jvQIsGg9pG3w/?utm_source=${arURL.utm_source}`,
  },
  facebook: {
    enabled: true,
    active: true,
    order: 4,
    label: "Facebook",
    icon: icons.facebook,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.facebook.com/attachmentrepair/?utm_source='${arURL.utm_source}'`,
  },
  reddit: {
    enabled: true,
    active: true,
    order: 5,
    label: "Reddit",
    icon: icons.reddit,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.reddit.com/r/attachment_repair/?utm_source='${arURL.utm_source}'`,
  }
}
