import { meditationUserData } from "../data/ar_globaldata"
import { libraryFilter } from "../ui_components/library_filter.js";
import * as tippy from "../ui_general/tippy.js";
import { restAPIRequest } from "../restapi/restapi_requests.js";
import { createMeditationRow } from "../meditation/meditation_row.js";
import { icons } from "../ui_general/icons.js";

export const sectionMeditation = document.createElement("section");
sectionMeditation.setAttribute("id", "meditation");
sectionMeditation.classList.add("membership", "hidden");
sectionMeditation.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon="${icons.postType.meditation}"></iconify-icon>
        <div class='label'>Meditation Library</div>
      </div>
    </div>

    <div class="contents">

      <div class="button_view_set">
        <div class='button_view_wrap current' data-name='library_main'>
          <div class="button_view">
            <iconify-icon noobserver icon='${icons.sortingLeft}'></iconify-icon>
            <div class="label">Library</div>
          </div>
          <div class="action_button" id="library_filter">
            <div class='status_dot'>o</div>
            <iconify-icon noobserver icon="${icons.search}"></iconify-icon>
            <div class="label">Filter</div>
          </div>
        </div>
        <!-- <div class="button_view" data-name='library_search'> -->
        <div class='button_view_wrap' data-name='library_recent'>
          <div class="button_view">
            <iconify-icon noobserver icon="${icons.clock}"></iconify-icon>
            <div class="label">Listened</div>
          </div>
        </div>
        <div class='button_view_wrap' data-name='library_favs'>
          <div class="button_view">
            <iconify-icon noobserver icon="${icons.fav.off}"></iconify-icon>
            <div class="label">Favourites</div>
          </div>
        </div>
      </div>

      <div class="block view_set current" id="library_main">

        <div id='meditation_list' class='meditation_list'>
        </div>

        <div id='meditation_list_prev_next_wrap' class='pagination_wrap'>
          <button-arrow direction="left" section="meditation"></button-arrow>
          <div class="pageCount">
            <div class="count_widget currentPage"></div>/
            <div class="count_widget totalPages"></div>
          </div>
          <button-arrow direction="right" section="meditation"></button-arrow>
        </div>

      </div>

      <div class="block view_set" id="library_recent">
        <div class="subheader">Recently Listened</div>
      </div>
      <div class="block view_set" id="library_favs">
        <div class="subheader">Favourites</div>
        <div class='meditation_list' id="library_favs_list"></div>
      </div>
      <div class="block view_set" id="library_search">
        <div class="subheader">Filter</div>
      </div>

    </div>
  </div>
`;

document.addEventListener('loginEvent', function (event) {
  uiGetMeditationPosts();
});

document.addEventListener("logoutEvent", () => {
  uiGetMeditationPosts();
})

document.addEventListener("favUpdateEvent", () => {
  updateFavsListDOM();
})
function updateFavsListDOM() {
  const favsListDOM = sectionMeditation.querySelector("div#library_favs_list");
  const favs = meditationUserData.data;
  console.log("updateFavsListDOM() favs:", favs);

  // const posts = Object.values(favs).map((value) => {
  //   return buildMeditationPostFromDOM(value);
  // });

  // console.log("updateFavsListDOM() posts:", posts);

  // for each favsID, we grab the meditations. We can't get them out of the DOM because they get deleted each time.
  // we could store them in localStorage instead of deleting from DOM. or keep in DOM and just hide/show.
  // or we actually store the post in IDB. then we can query that instead of querying the API, and we can also use that for favs.

  // so when adding to favs, at the moment of saving the meditationId, we could grab and assemble a
  // post object that is structured the same as the one the REST API returns. that way we can use the same processMeditatinPosts function.

  processMeditationPosts({ posts: Object.values(favs), target: favsListDOM });
}

export const meditationLibraryState = {
  page: 1,
  isLoading: false,
  totalPages: undefined,
}

export const libraryMain = sectionMeditation.querySelector("div#library_main");
export const filterButton = sectionMeditation.querySelector("div#library_filter");

// Define handlers for showing and hiding the filter.
const onShowHandler = (instance) => {
  // Clear any existing content from the tooltip and append libraryFilter.
  instance.setContent("");
  instance.setContent(libraryFilter);
  filterButton.classList.add("active");
};

const onHiddenHandler = (instance) => {
  // Ensure libraryFilter goes back to libraryMain when the tooltip is hidden.
  if (!libraryMain.contains(libraryFilter)) {
    libraryMain.append(libraryFilter);
    filterButton.classList.remove("active");
  }
};

export const filterMenu = tippy.initialiseTippyOn({
  element: filterButton,
  theme: "library_filter",
  placement: "bottom",
  content: "", // Start empty
  allowHTML: true,
  offset: [0, 10],
  onShow: onShowHandler,
  onHidden: onHiddenHandler,
  animation: "shift-away",
  duration: [200, 100],
});


// Page Events
document.addEventListener("DOMContentLoaded", () => {

  // [] Save state of filter into LocalStorage each time tippy is closed.
  // [] Read state of LocalStorage and set filter inputs
  // [x] Send state of Filter over REST API

  // Populate Favs
  const dbFavs = meditationUserData.loadMeditationsFromDB().then((result) => {
    processMeditationPosts({
      posts: result,
      target: sectionMeditation.querySelector("div#library_favs_list"),
    });
  });

  const libraryMain = sectionMeditation.querySelector("div#library_main");

  // Check if libraryFilter is not yet appended to the libraryMain and append it
  if (libraryMain && !libraryMain.contains(libraryFilter)) {
    libraryMain.append(libraryFilter);
  }

});

document.addEventListener("meditation_load_event", async (event) => {
  if (meditationLibraryState.isLoading) return;

  console.log('meditation_load_event:', event);
  console.log('meditationlib', meditationLibraryState)
  const { direction } = event.detail;

  meditationLibraryState.isLoading = true;

  if (direction === "left" && meditationLibraryState.page > 1) {
    meditationLibraryState.page -= 1;
  } else if (direction === "right") {
    meditationLibraryState.page += 1;
  }

  try {
    // Invoke the network request and wait for it to complete
    await uiGetMeditationPosts({
      page: meditationLibraryState.page,
      postsPerPage: 20,
    });

  } catch (error) {
    console.error("Failed to fetch Meditation posts:", error);
  } finally {
    console.log('dispatch', meditationLibraryState)
    const loadCompleteEvent = new CustomEvent('restAPILoadComplete', {
      bubbles: true,
      composed: true,
      detail: {
        page: meditationLibraryState.page,
        totalPages: meditationLibraryState.totalPages,
        section: "meditation",
      }
    });
    document.dispatchEvent(loadCompleteEvent);
    meditationLibraryState.isLoading = false;
  }

})


// Functions
export function uiGetMeditationPosts(argConfig = {}) {

  const defaultConfig = {
    page: meditationLibraryState.page,
    postsPerPage: 20,
    postType: "meditation",
  };

  const config = { ...argConfig, ...defaultConfig }

  console.log('uiGetMeditationPosts()', config)

  const libraryMain = sectionMeditation.querySelector("div#library_main");
  const meditationList = sectionMeditation.querySelector("div#meditation_list");
  const meditationPagination = sectionMeditation.querySelector("div#library_main div.pagination_wrap");
  const loadSpinner = document.createElement("loading-spinner");

  if (libraryMain && !libraryMain.contains(loadSpinner)) {
    libraryMain.appendChild(loadSpinner);
  }
  meditationList.classList.add("global_blur");
  meditationPagination.classList.add("global_blur");

  return restAPIRequest(config)
    .then((data) => {
      meditationLibraryState.page = data.page;
      meditationLibraryState.totalPages = data.totalPages;

      processMeditationPosts(data);

      document.dispatchEvent(
        new CustomEvent('restAPILoadComplete', {
          bubbles: true,
          composed: true,
          detail: {
            page: data.page,
            totalPages: data.totalPages,
            section: "meditation",
          }
        })
      );
    })
    .finally(() => {
      const loadingSpinner = libraryMain.querySelector("loading-spinner");

      if (loadingSpinner) {
        libraryMain.removeChild(loadingSpinner);
      }

      meditationList.classList.remove("global_blur");
      meditationPagination.classList.remove("global_blur");

    })
    .catch((error) => {
      console.error('uiGetMeditationPosts()', error);
      // todo: [] - append error message to dom.
      meditationList.innerHTML = "Couldn't load meditations...";
    });
}

export function processMeditationPosts({ posts: posts, target: target, totalPages: totalPages, page: page } = {}) {
  // console.log("processMeditationPosts()", posts, "target", target);
  // console.log("Meditations:", posts, "Total Pages:", totalPages);

  const meditationList = sectionMeditation.querySelector("div#meditation_list");
  if (target === undefined) target = meditationList;
  // console.log("processMeditation: target: ", target);

  // Update pages count widgets
  sectionMeditation.querySelector("#meditation_list_prev_next_wrap div.totalPages").innerText = totalPages || "";
  sectionMeditation.querySelector("#meditation_list_prev_next_wrap div.currentPage").innerText = page || "";

  // Clear list
  target.innerHTML = "";

  // Update list
  posts.forEach((post) => {
    const meditationRow = createMeditationRow({ meditation: post, page: "library" });
    target.appendChild(meditationRow);
  });
  return { posts: posts, target: target, totalPages: totalPages, page: page };
}

/*

const favs = meditationUserData
  .loadMeditationsFromDB()
  .then((favs) => {
    posts.forEach((post) => {
      const isFavourite = favs.some(
        (fav) => Number(fav.meditationId) === Number(post.meditationId),
      );
      if (isFavourite) post.favourite = true;
    });

*/
