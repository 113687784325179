import $ from "jquery";
import * as db from "../database/indexdb.js";
import { mainMenu } from "../ui_components/membership_menu.js";
import * as ar from "../ui_general/ar_globals.js";
import * as sync from "./ar-chat-sync.js";
import { ChatBuilder } from "./chatBuilder.js";
import { billingBlock, billingBlockCallback } from "../blocks/billingBlock.js";
import { slidePane } from "../ui_components/slidePane.js";
import { icons } from "../ui_general/icons.js";
// console.log("Loaded: ar-chatbot.js");

const chatLimit = ar.userDataObj.account.isLoggedIn ? 25 : 3;
export const chatObserve = setupChatObserve();

$(document).ready(async function onLoad($) {
  // document.addEventListener("DOMContentLoaded", () => {
  // console.log('DOM ready on ar-chatbot.js')

  // Global DOM Chat layout
  const chatBotSection = $("#chatbot");
  const chatListContainer = $("#chat-list-container");
  const chatList = $("#chat-list");
  const mainChatArea = $("#main-chat-area");

  resetChatArea();

  // Back to List
  const $backToList = $(
    `<div class='back_button'>
      <iconify-icon noobserver icon='${icons.arrowLeft2}'></iconify-icon>
    </div>`,
  );

  $backToList.on("click", () => {
    mainChatArea.removeClass("in");
    chatListContainer.removeClass("out");
  });

  mainChatArea.append($backToList);

  // bind to Sync button
  $("div.sync").on("click", function () {
    console.log("🔄 Sync button");
    sync.syncChatsToServer();
  });

  $(document).on("keydown", function (e) {
    if (e.shiftKey && e.key === "S") {
      console.log("🔄 Sync button");
      sync.syncChatsToServer();
    }
  });

  // Draggable sidebar
  // new Sortable(chatList[0], {
  //     animation: 250,
  //     swapThreshold: 0.15,
  //     chosenClass: "sortable-chosen",
  //     dragClass: "sortable-drag",
  //     direction: 'vertical',
  // });

});

export function resetChatArea() {
  const chatList = document.querySelector("div#chat-list")
  const newChatButton = document.querySelector("button#new-chat");

  if (!chatList) return;

  ar.appState.chatInit = false;

  // Clear the chat area
  chatList.innerHTML = "";
  document.querySelector("div.chat_count").textContent = "";
  newChatButton.removeEventListener("click", initNewChatButton);
  newChatButton.classList.add("global_hidden");

  // Kill the observer
  chatObserve.disconnect();

  // Initial Login button
  const buttonGoToLoginForm = document.createElement("button-std");
  buttonGoToLoginForm.setAttribute("id", "chat_loginbutton")
  buttonGoToLoginForm.textContent = "Login";
  buttonGoToLoginForm.addEventListener('click', () => {
    mainMenu.querySelector("div.tab[data-membership='home'").click();
  });

  const loginMessageBox = document.createElement("div");
  loginMessageBox.setAttribute("id", "loginMessageBox");
  loginMessageBox.innerHTML = `
      <div id="chatLoginMessageBox">Please log in to use the chat</div>
    `;
  loginMessageBox.appendChild(buttonGoToLoginForm)

  // Login message for free tier.
  if (chatList && !chatList.querySelector("div#loginMessageBox")) {
    chatList.appendChild(loginMessageBox);
  }
}

export function setupChatObserve() {

  const observeChatList = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList") {

        // Update count
        const chatList = document.querySelector("div#chat-list");
        const chatListContainer = document.querySelector("div#chat-list-container");
        const chatCount = Array.from(chatList.children)
          .filter(child => child.classList.contains("chat_sidebar"))
          .length;

        const chatCountDisplay = chatCount > 0 ? chatCount : "";
        const chats = document.querySelector("div#chat-list");

        // Update chat count display
        const chatCountElement = chatListContainer.querySelector("div.chat_count");
        if (chatCountElement) {
          chatCountElement.textContent = chatCountDisplay;
        }

        // Disable/enable new chat button based on chat limit
        const newChatButton = chatListContainer.querySelector("#new-chat");

        if (newChatButton) {
          newChatButton.disabled = chatCount >= chatLimit;
        }

        // Print a welcome message if chat is empty
        const printedStartMessage = document.querySelector("div#startNewChatMessage");
        const startNewChatMessage = document.createElement("div");

        // Print new chat button into container
        const newChatButtonContainer = document.createElement('div');
        newChatButtonContainer.innerHTML = `<button-std type='flat' id="new-chat" hue='220' icon='iconamoon:sign-plus-bold'>New Chat</button-std>`;
        const newChatButtonElement = newChatButtonContainer.firstChild;
        newChatButtonElement.addEventListener('click', () => {
          const newChat = new ChatBuilder({});
          newChat.selectChat();
        })

        startNewChatMessage.setAttribute("id", "startNewChatMessage");
        startNewChatMessage.appendChild(newChatButtonElement);

        // console.log('chatcount:', chatCount, 'printedmsg', printedStartMessage)
        // Check if chatlist empty
        if (chatCount === 0) {
          if (!printedStartMessage) {
            chats.appendChild(startNewChatMessage);
            // console.log('💬 Add startNewChatMessage');
          }
          document.querySelector("div.chat_count").classList.add("global_hidden");
          document.querySelector("button#new-chat").classList.add("global_hidden");
        } else if (printedStartMessage) {
          chats.removeChild(printedStartMessage);
          document.querySelector("div.chat_count").classList.remove("global_hidden");
          document.querySelector("button#new-chat").classList.remove("global_hidden");
          // console.log('💬 Remove startNewChatMessage');
        }

        // Check limit for max chats
        const printedProMessage = document.querySelector("div#chat-list div#proMessage");
        if (chatCount >= chatLimit) {
          if (!printedProMessage) {
            const reasonForProMessage = `Maximum chats reached: ${chatLimit}`;
            const proMessage = document.createElement("div");
            const upgradeButton = document.createElement("button-std");
            upgradeButton.textContent = "Upgrade to Pro";
            upgradeButton.addEventListener("click", () => {
              slidePane(
                {
                  element: billingBlock,
                  title: 'Membership',
                  callback: billingBlockCallback,
                }
              );
            })

            proMessage.setAttribute("id", "proMessage");
            proMessage.innerHTML = `
                  <div class='reason_header'>${reasonForProMessage}</div>
                  <div class='upgrade_to_pro'>Upgrade to Pro</div>
                  <div class='pro_benefits'> a b c </div>
                  <div class='upgrade_button'>
                  </div>
                `;

            proMessage.querySelector('div.upgrade_button').append(upgradeButton);

            chats.appendChild(proMessage);
          }
        } else if (printedProMessage) {
          chats.removeChild(printedProMessage);
        }
      }
    }
  });

  // observeChatList.observe(document.querySelector("div#chat-list"), { childList: true });
  return observeChatList;
}

export function initNewChatButton() { newChat() }

// Init Chat
export async function initChat() {
  // console.info("💬 initChat()");
  ar.appState.chatInit = true;
  const loadSpinner = document.createElement("loading-spinner");
  const domChatList = document.querySelector("div#chat-list")
  const newChatButton = document.querySelector("button#new-chat");
  const chatBotSection = $("#chatbot");
  const chatListContainer = $("#chat-list-container");
  const chatList = $("#chat-list");
  const mainChatArea = $("#main-chat-area");


  // Chatlist top corner button
  newChatButton.addEventListener("click", initNewChatButton);

  chatObserve.observe(document.querySelector("div#chat-list"), { childList: true });

  try {
    domChatList.appendChild(loadSpinner);

    const serverData = await sync.fetchServerChatData();
    const idbData = await sync.loadIDBChatData();

    if (serverData && idbData) {
      const reconciledData = await sync.reconcileChatData(serverData, idbData);

      // console.info("💬 initChat(): idbData: ", idbData);
      // console.info(
      //   "💬 initChat(): ⚡ Reconciled Data from server + IDB: ",
      //   reconciledData,
      // );

      if (reconciledData.length) {
        // Populate sidebar
        reconciledData.forEach(
          (chatDataInstance) => new ChatBuilder(chatDataInstance, false),
        );
      }

      db
        .saveToIndexedDB({
          storeName: "chatInstanceData",
          actionType: "update",
          data: reconciledData,
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => console.error(error));

    } else {
      const newChat = new ChatBuilder({});
    }
    // updateChatInterface(reconciledData);
  } catch (error) {
    console.error("Failed to initialize chat:", error);
  } finally {
    if (domChatList.contains(loadSpinner)) {
      domChatList.removeChild(loadSpinner);
    }
  }


}

export function newChat() {
  const chatList = document.querySelector("div#chat-list");

  // console.log('newChat(): chatLimit:', chatLimit)
  if (chatList.children.length < chatLimit) {
    const newChat = new ChatBuilder({});
    newChat.selectChat();
  }
}

document.addEventListener('loginEvent', function (loginEvent) {
  // console.log(' --- Chat heard loginEvent.')

  const { isLoggedIn } = loginEvent.detail;

  if (isLoggedIn) {
    if (!ar.appState.chatInit) {
      initChat();
    }

    const loginMessageBox = document.querySelector("div#loginMessageBox");
    if (loginMessageBox) {
      loginMessageBox.remove();
    }
  } else {
    // console.log(' not logged in');
    function testButton() {
      mainMenu.querySelector("div.tab[data-membership='home'").click();
    }

    const chatList = document.querySelector("div#chat-list")
    const buttonGoToLoginForm = document.createElement("button-std");
    buttonGoToLoginForm.setAttribute("id", "chat_loginbutton")
    buttonGoToLoginForm.addEventListener('click', testButton);
    const loginMessageBox = document.createElement("div");
    loginMessageBox.setAttribute("id", "loginMessageBox");
    loginMessageBox.innerHTML = `
          <div id="chatLoginMessageBox">Please log in to use the chat</div>
        `;
    loginMessageBox.appendChild(buttonGoToLoginForm)

    if (chatList && !chatList.querySelector("div#loginMessageBox")) {
      buttonGoToLoginForm.textContent = "Login";
      chatList.appendChild(loginMessageBox);
    }

  }
});

document.addEventListener('logoutEvent', function (logoutEvent) {
  // console.log('chat: logoutEvent detected');
  resetChatArea();
});
