export const terms_issue_targeted = [
  {
    "name": "Abandonment Fear",
    "slug": "abandonment-fear",
    "count": 7,
    "description": "",
    "id": 242,
    "parent": 0
  },
  {
    "name": "Addiction / Substance Abuse",
    "slug": "addiction-substance-abuse",
    "count": 5,
    "description": "",
    "id": 232,
    "parent": 0
  },
  {
    "name": "Co-dependency",
    "slug": "co-dependency",
    "count": 3,
    "description": "",
    "id": 238,
    "parent": 0
  },
  {
    "name": "Confidence &amp; Exploration",
    "slug": "confidence-exploration",
    "count": 5,
    "description": "",
    "id": 231,
    "parent": 0
  },
  {
    "name": "Conscious Parenting",
    "slug": "conscious-parenting",
    "count": 3,
    "description": "",
    "id": 229,
    "parent": 0
  },
  {
    "name": "CPTSD",
    "slug": "cptsd",
    "count": 9,
    "description": "",
    "id": 204,
    "parent": 0
  },
  {
    "name": "Dating Issues",
    "slug": "dating-issues",
    "count": 6,
    "description": "",
    "id": 211,
    "parent": 0
  },
  {
    "name": "Depression",
    "slug": "depression",
    "count": 5,
    "description": "",
    "id": 247,
    "parent": 0
  },
  {
    "name": "Dissociation",
    "slug": "dissociation",
    "count": 0,
    "description": "",
    "id": 251,
    "parent": 0
  },
  {
    "name": "Executive Dysfunction",
    "slug": "executive-dysfunction",
    "count": 6,
    "description": "",
    "id": 241,
    "parent": 0
  },
  {
    "name": "Filial Piety",
    "slug": "filial-piety",
    "count": 0,
    "description": "",
    "id": 235,
    "parent": 0
  },
  {
    "name": "Forgiveness",
    "slug": "forgiveness",
    "count": 1,
    "description": "",
    "id": 250,
    "parent": 0
  },
  {
    "name": "General Anxiety",
    "slug": "general-anxiety",
    "count": 7,
    "description": "",
    "id": 252,
    "parent": 0
  },
  {
    "name": "Grief",
    "slug": "grief",
    "count": 2,
    "description": "",
    "id": 230,
    "parent": 0
  },
  {
    "name": "Integration and Parts",
    "slug": "integration-and-parts",
    "count": 11,
    "description": "",
    "id": 240,
    "parent": 0
  },
  {
    "name": "Lack of Positive Emotion (Anhedonia)",
    "slug": "anhedonia",
    "count": 4,
    "description": "",
    "id": 249,
    "parent": 0
  },
  {
    "name": "Loneliness",
    "slug": "loneliness",
    "count": 7,
    "description": "",
    "id": 239,
    "parent": 0
  },
  {
    "name": "Metacognition",
    "slug": "metacognitive-development",
    "count": 8,
    "description": "",
    "id": 236,
    "parent": 0
  },
  {
    "name": "Obsessive-Compulsive Disorder",
    "slug": "obsessive-compulsive-disorder",
    "count": 3,
    "description": "",
    "id": 205,
    "parent": 0
  },
  {
    "name": "Self Definition / Agency / Esteem",
    "slug": "self-agency",
    "count": 9,
    "description": "",
    "id": 233,
    "parent": 0
  },
  {
    "name": "Sex Issues",
    "slug": "sex-issues",
    "count": 0,
    "description": "",
    "id": 234,
    "parent": 0
  },
  {
    "name": "Shame",
    "slug": "shame",
    "count": 9,
    "description": "",
    "id": 237,
    "parent": 0
  },
  {
    "name": "Social Anxiety",
    "slug": "social-anxiety",
    "count": 4,
    "description": "",
    "id": 209,
    "parent": 0
  },
  {
    "name": "Trauma",
    "slug": "trauma",
    "count": 8,
    "description": "",
    "id": 212,
    "parent": 0
  }
];
