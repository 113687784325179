export const localStorageFieldsUserData = [
  'AR_Userdata_Account',
  'AR_Userdata_JWT',
  'AR_Userdata_PersonalFields',
  'AR_Userdata_JournalEntries',
  'AR_Userdata_Settings_theme',
  'AR_Userdata_Settings_libraryFilter_duration',
  'AR_Userdata_Settings_libraryFilter_purpose',
  'AR_Userdata_Settings_libraryFilter_issue_targeted',
  'AR_Userdata_Settings_libraryFilter_title',
  'AR_Userdata_Settings_libraryFilter_schema',
];

export const localStorageFieldsAppData = [
  'AR_Appdata_TaxonomyData.slug.issue_targeted',
  'AR_Appdata_TaxonomyData.slug.purpose'
];
