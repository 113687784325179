export function assessmentsBlockCallback() {

}

export const assessmentsBlock = document.createElement("div");
assessmentsBlock.classList.add('block_wrap');
assessmentsBlock.setAttribute("id", "assessmentsBlock")
assessmentsBlock.innerHTML = `

`;
