import { printSPCPill, printTaxonomyPills } from "./meditation_row";
import { createJournalPane } from "../journal/journal_entries";


export function createMeditationPaneContents(row) {

  const meditationPaneContents = document.createElement('div');
  meditationPaneContents.classList.add('meditation_contents');

  const playerContainer = document.createElement('div');
  playerContainer.classList.add('meditation_player_container');
  meditationPaneContents.appendChild(playerContainer);

  const player = window.createMeditationPlayer(row.dataset.meditationId);
  window.meditationPlayerList[row.dataset.meditationId].player = player;
  playerContainer.appendChild(player);

  const description = document.createElement('div');
  description.classList.add('description');
  description.innerHTML = `
      ${row.dataset.description || "In this meditation, <span style='opacity: 0.4'>placeholder description... quod aliquam libero libero laudantium vitae voluptatem. Ut voluptates eos eum dolores possimus quia repellendus recusandae. Nostrum ipsam pariatur ad et qui et culpa laboriosam.</span>"}
  `;
  meditationPaneContents.appendChild(description);

  const taxonomyPills = printTaxonomyPills(row)
  if (taxonomyPills) {
    meditationPaneContents.appendChild(taxonomyPills);
  }

  const spcPill = printSPCPill(row)
  if (spcPill) {
    meditationPaneContents.appendChild(spcPill);
  }

  meditationPaneContents.insertAdjacentElement('beforeend', createJournalPane(row));

  return meditationPaneContents;
}
