import { slidePane } from "../ui_components/slidePane";
import { createMeditationRow } from "../meditation/meditation_row";
import { icons } from "../ui_general/icons";

export function linkToSPCPaneBlock(spcPost) {
  // console.log('spcPost', spcPost)
  const spcPaneBlock = createSPCPaneBlock(spcPost);

  slidePane(
    {
      element: spcPaneBlock,
      title: "Self-Paced Course",
      callback: { open: createSPCFilesList(spcPost, spcPaneBlock) },
      dataset: spcPost,
    }
  );
}

export function createSPCFilesList(post, spcPaneBlock) {
  // console.log('createSPCFilesList()')
  // print the modules list here
  if (post.includedModules) {
    const filesList = post.includedModules.map((module, index) => {
      // console.log('module', module)
      const moduleContainer = document.createElement("div");
      moduleContainer.classList.add('module_container');
      moduleContainer.innerHTML = `
        <div class='module_header'>
          <div class='index'>${index + 1}</div>
          <div class='module_title'>${module.module_title}</div>
        </div>

        <div class='module_files'>
        </div>
      `;

      if (module.files) {

        module.files.forEach(file => {

          const fileElement = document.createElement("div");
          fileElement.classList.add("file");
          fileElement.dataset['canAccess'] = file.canAccess;

          if (file.acf_fc_layout === "lecture") {
            const fileClass = 'lecture';
            fileElement.classList.add(fileClass);
            let format; // video|audio|noAccess|null

            // Check the canAccess value here.
            // console.log('spcBlock file', file)
            // console.log('spcBlock canAccess?', file.canAccess);

            // Display a padlock instead of the audio/video player if locked.

            let player = "";

            if (!file.lecture_file) {
              format = null;
              player = `<div class='no_lecture'></div>`;
            } else {
              if (file.canAccess) {

                if (file.video_url) {
                  format = 'video';
                  player = `
                    <video controls>
                      <source src="${file.video_url}" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>`;
                } else if (file.audio_url) {
                  format = 'audio'
                  player = `
                    <audio controls>
                      <source src="${file.audio_url}" type="video/mp4">
                      Your browser does not support the audio tag.
                    </audio>`;
                }
              } else {
                format = 'noAccess'
                player = `
                  <div class='fileNoAccess'>
                    <iconify-icon noobserver class='noaccess' icon="${icons.padlock}"></iconify-icon>
                  </div>`;
              }
            }

            // Define HTML
            if (format === null) {
              fileElement.innerHTML = null;
            } else {

              fileElement.innerHTML = `
                <div class='lecture'>
                  <div class='player ${format}' data-can-access='${file.canAccess}'>
                    ${player}
                  </div>
                  <div class='lecture_title'>${file.title}</div>
                  <div class='lecture_duration'>${file.duration} mins</div>
                </div>
                `;
            }

          } else if (file.acf_fc_layout === "meditation") {
            const fileClass = 'meditation';
            fileElement.classList.add(fileClass);

            const meditation = file.meditation_library_all;
            const meditationRow = createMeditationRow({ meditation: meditation, page: "spc" });
            fileElement.appendChild(meditationRow)

          } else if (file.acf_fc_layout === "document") {
            const fileClass = 'document';
            fileElement.classList.add(fileClass);
            fileElement.innerHTML = `
              <div class='document'>
                <div class='document_title'>${file.title}</div>
                <div class='document_format'>${file.format.label}</div>
              </div>
              `;
          };

          moduleContainer.querySelector('.module_files').appendChild(fileElement);

        })

      }

      return moduleContainer;
    });

    filesList.forEach(fileElement => {
      spcPaneBlock
        .querySelector('div.modules_list')
        .appendChild(fileElement);
    })

  }

}

export function createSPCPaneBlock(post) {
  // console.log('createSPCPaneBlock()');
  // console.log('post', post)
  const spcPaneBlock = document.createElement("div");
  spcPaneBlock.classList.add('block', 'spc_block');
  spcPaneBlock.dataset['canAccess'] = post.canAccess;
  spcPaneBlock.innerHTML = `
    <div class='image_header'>
      <img src='${post.images.spc_image_url_full}' />
    </div>

    <div class='spc_title'>${post.title}</div>

    <div class='summary'>${post.summary}</div>

    <completion-bar></completion-bar>

    <div class='modules_list'>
    </div>
  `;

  return spcPaneBlock
}
