function findParentWithAttribute(element, attributeName) {
  let currentElement = element;

  while (currentElement !== null) {
    if (currentElement.hasAttribute(attributeName)) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
}

export function parseDateYYYYMMDD(dateYYYYMMDD) {
  return new Date(
    dateYYYYMMDD.slice(0, 4),    // Year
    dateYYYYMMDD.slice(4, 6) - 1, // Month (subtract 1)
    dateYYYYMMDD.slice(6, 8)     // Day
  );
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

window.findParentWithAttribute = findParentWithAttribute;
window.uuidv4 = uuidv4;
