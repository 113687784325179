import { createMeditationRow } from "../meditation/meditation_row";
import { icons } from "../ui_general/icons";
import { updateUserData, getUserData } from "../restapi/restapi_update_user";
import { createMenuWidget } from "../ui_components/menu_widget";

export function journalEntry(journalEntryObj, linkToMeditation = false) {
  const date = dateformat(journalEntryObj.date, 'fullDate');
  const journalEntryDiv = document.createElement('div');
  journalEntryDiv.classList.add('journal_entry');
  journalEntryDiv.dataset.uuid = journalEntryObj.uuid;
  journalEntryDiv.dataset.meditationId = journalEntryObj.meditationId;
  journalEntryDiv.innerHTML = `
    <div class='journal_contents'>

      <div class='journal_date'>
        <iconify-icon noobserver icon='${icons.postType.journal}'></iconify-icon>
        <div class='date'>${date}</div>
      </div>

      <div class='journal_text'>${journalEntryObj.text}</div>
    </div>
  `;

  if (linkToMeditation) {
    journalEntryDiv.innerHTML += `<div class='from'>From</div>`;
    journalEntryDiv.appendChild(makeMeditationMiniWidget(journalEntryObj.meditationId, journalEntryObj));
  }

  // Menu button
  const deleteButton = createMenuWidget('delete', journalEntryObj.uuid);
  journalEntryDiv.insertAdjacentElement('afterbegin', deleteButton);

  return journalEntryDiv;
}

export function createJournalPane(row) {
  const maxLength = 5000;
  const journalEntry = document.createElement('div');
  journalEntry.classList.add('meditation_journal_container');
  journalEntry.innerHTML = `
    <div class='subheader'>Journal</div>
    <textarea class='meditation_journal' rows='8' maxLength='${maxLength}'></textarea>
  `;

  const textArea = journalEntry.querySelector('textarea.meditation_journal');

  const existingJournal = getJournalForMeditationLS(row.dataset.meditationId);
  if (existingJournal) {
    textArea.value = existingJournal.text;
  }

  // Build list of previous entries
  journalEntry.insertAdjacentElement('beforeend', getPreviousJournalEntries(row.dataset.meditationId));

  // Limit length
  ['input', 'change'].forEach(listener => {
    textArea.addEventListener(listener, () => {
      if (textArea.value.length > maxLength) {
        textArea.value = textArea.value.substring(0, maxLength);
      }
    });
  });

  // Store Journal entry on close
  textArea.addEventListener('blur', () => {
    saveNewJournalEntry(textArea.value, row.dataset.meditationId, row.dataset);
  });

  return journalEntry;
}

export async function updateServerWithUserData() {
  /**

    This function would be fine to use with a service-worker
    or when the user leaves the app.

  **/
  const reconciledData = await reconcileUserData('journal_data');

  if (reconciledData) {
    console.log('dataToSend', reconciledData);
    updateUserData({ journal_data: JSON.stringify(reconciledData) });
  }
}

export function makeMeditationMiniWidget(meditationId, journalEntryObj) {
  // console.log('obj', journalEntryObj.meditationData)
  const meditationRow = createMeditationRow({
    meditation: journalEntryObj.meditationData,
    showTaxonomies: false
  });
  meditationRow.classList.add('mini');
  return meditationRow
}

export function displayJournalEntries(journalEntries, target, link_to_meditation = true) {
  if (!journalEntries) return;

  if (!target) {
    target = document.querySelector('section#journal div#meditation_journal_previous');
  }

  journalEntries.sort((a, b) => a.date < b.date);

  if (journalEntries) {
    target.innerHTML = '';
    journalEntries.forEach(entry => {
      if (!entry.hasOwnProperty('deleted')) {
        console.log('show', entry)
        const journalDiv = journalEntry(entry, link_to_meditation);
        target.appendChild(journalDiv);
      }
    });
  }
}

export function journalToday() {
  return new Date();
}

export function getJournalForMeditationLS(meditationId, date = journalToday()) {

  const journalEntries = JSON.parse(localStorage.getItem("AR_Userdata_JournalEntries"));

  if (journalEntries) {
    return journalEntries.find(entry =>
      entry.date === date && entry.meditationId === meditationId
    );
  }
}

export function createJournalEntryObj(text, meditationId, dataset) {
  const newJournalObj = {
    date: journalToday(),
    meditationId: meditationId,
    text: text,
    meditationData: dataset,
    uuid: uuidv4(),
  };
  return newJournalObj
}

export function saveNewJournalEntry(text, meditationId, dataset) {

  const newJournalObj = createJournalEntryObj(text, meditationId, dataset);

  let journalEntries = JSON.parse(localStorage.getItem("AR_Userdata_JournalEntries")) ?? [newJournalObj];

  // Update an existing entry?
  const updatedJournal = journalEntries.find(entry => {
    const updateEntry =
      entry.date === newJournalObj.date &&
      entry.meditationId === newJournalObj.meditationId
    if (updateEntry) {
      return entry.text = newJournalObj.text;
    }
  });

  // Push a new entry?
  if (!updatedJournal) {
    journalEntries.push(newJournalObj)
  }

  // Filter empty entries
  journalEntries = journalEntries.filter(entry => entry.text);

  console.log('save', journalEntries)

  // Save the data...
  // ...to Local Storage
  localStorage.setItem("AR_Userdata_JournalEntries", JSON.stringify(journalEntries));

  displayJournalEntries(journalEntries);

  updateServerWithUserData();

}

export async function reconcileUserData(keyName) {

  // [] Make it work with multiple keys.
  // [] Key needs to dynamically get the right localstorage object too

  const localData = JSON.parse(localStorage.getItem("AR_Userdata_JournalEntries")) ?? [];
  const serverData = await getUserData([keyName]);

  console.log('localData', localData);
  console.log('serverData', serverData[keyName])

  if (serverData[keyName] && serverData.status === 200) {
    const mergedData = mergeLocalAndServerData(localData, serverData.data, keyName);

    console.log('mergedData', mergedData)

    // Save as the new canonical local source
    localStorage.setItem("AR_Userdata_JournalEntries", JSON.stringify(mergedData));

    return mergedData;
  } else {
    return localData;
  }
}

function mergeLocalAndServerData(localCopy, serverCopy, keyName) {
  // merge then compare timestamps for dupes, keep newest

  const merged = [...localCopy]; // Start with localCopy

  serverCopy[keyName].forEach(serverItem => {
    const existingIndex = merged.findIndex(localItem => localItem.uuid === serverItem.uuid);

    if (existingIndex === -1) {
      // If the uuid does not exist, keep the serverItem
      merged.push(serverItem);
    } else {
      // If the uuid exists...

      // Keep it if its a record of a deleted uuid
      if (serverItem.deleted === true) {
        merged[existingIndex] = serverItem;
      }

      // Keep whichever is newer
      if (new Date(serverItem.date) > new Date(merged[existingIndex].date)) {
        merged[existingIndex] = serverItem;
      }
    }
  });

  console.log('merged', merged);

  // const filtered = Object.values(merged).reduce((acc, obj) => {
  //   const current = acc[obj.uuid];

  //   if (!current || obj.date > current.date) {
  //     return acc;
  //   }
  // }, {});

  // console.log('filtered', filtered);

  return Object.values(merged);
}

export function getPreviousJournalEntries(meditationId) {
  const journalEntries = JSON.parse(localStorage.getItem("AR_Userdata_JournalEntries"));
  const entriesContainer = document.createElement('div');

  if (journalEntries) {

    const filteredJournals = journalEntries.filter(entry =>
      entry.meditationId === meditationId && entry.date !== journalToday()
    );

    if (filteredJournals.length) {
      entriesContainer.setAttribute('id', 'meditation_journal_previous');
      entriesContainer.innerHTML = `<div class='subheader'>Previous Entries</div>`;

      displayJournalEntries(filteredJournals, entriesContainer, false);
    }
  }

  return entriesContainer;
}

export function deleteJournalData(uuidToDelete) {
  // get entries from LS
  const journalEntries = JSON.parse(localStorage.getItem("AR_Userdata_JournalEntries"));
  console.log('saved entries', journalEntries)

  // filter out this one
  const filteredEntries = journalEntries.map(entry => {
    if (entry.uuid === uuidToDelete) {
      entry.deleted = true;
      entry.text = null;
      entry.meditationData = null;
    }
    return entry
  });
  console.log('filtered entries', filteredEntries)

  // save it back to LS
  localStorage.setItem("AR_Userdata_JournalEntries", JSON.stringify(filteredEntries));

  // call server func
  updateUserData({ journal_data: JSON.stringify(filteredEntries) });
}

export function animateDeletingJournal(uuid) {
  document.querySelectorAll(`div.journal_entry[data-uuid='${uuid}']`).forEach(journal => {
    const journalHeight = journal.offsetHeight + 'px';
    console.log('h', journalHeight)

    journal.style.setProperty('height', journalHeight);
    journal.style.height = journalHeight;

    setTimeout(() => {
      journal.dataset.delete = true;
    }, 100);

    function hideJournalFromDOM() {
      /**
        We animate the marginBottom collapsing, after the CSS transitions finish.
        This makes it smoother, and allows us to delete the element in a separate step after the marginBottom finishes.
      **/
      journal.style.setProperty('margin-bottom', '0', 'important');
    }

    journal.addEventListener('transitionend', () => {
      hideJournalFromDOM();
      journal.removeEventListener('transitionend', hideJournalFromDOM);

      journal.addEventListener('transitionend', () => {
        journal.remove();
      })
    })
  })
}
