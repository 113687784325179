import * as ui from "../ui_general/menus.js";
import * as tippy from "../ui_general/tippy.js";
import { icons } from "../ui_general/icons";
import { meditationMenu, deleteMenu } from "../ui_general/menu_list.js";

export function createMenuWidget(type = 'default', dataset = {}, target, orientation) {

  const menuButton = createMenuButton(type, orientation, dataset);

  switch (type) {
    case "meditation":
      attachMeditationRowMenu(menuButton, dataset);
      break;
    case 'delete':
      attachDeleteMenu(menuButton, dataset);
      break;
  }

  return menuButton;
}

export function createMenuButton(type = 'default', orientation = 'vertical') {

  const icon = {
    'meditation': icons.menuDots,
    'delete': icons.bin,
    'default': icons.menuDots,
  };

  const menuWidget = document.createElement('div');
  menuWidget.classList.add('menu_widget');
  menuWidget.innerHTML = `
    <iconify-icon noobserver icon='${icon[type]}' class='${type} ${orientation}'></iconify-icon>
  `;

  return menuWidget;
}

export function attachDeleteMenu(target, dataset) {
  let tippyInstance;

  const menuItems = deleteMenu(dataset, tippyInstance);
  const menuObj = new ui.CreateMenu(menuItems);

  if (!target._tippy) {
    tippyInstance = tippy.initialiseTippyOn({
      element: target,
      theme: "menu",
      placement: "top",
      content: menuObj.menu,
      allowHTML: true,
      offset: [-20, 10],
    });
    return tippyInstance;
  }
}

export function attachMeditationRowMenu(target, dataset) {

  const menuObj = new ui.CreateMenu(meditationMenu(dataset));

  if (!target._tippy) {
    tippy.initialiseTippyOn({
      element: target,
      theme: "menu",
      placement: "left",
      content: menuObj.menu,
      allowHTML: true,
      offset: [0, 10],
    });
  }
}
