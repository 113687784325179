document.addEventListener("load", () => {
  window.addEventListener("beforeinstallprompt", (event) => {
    console.log('beforeinstallprompt fired');
    sectionStart.querySelector('div#install_app').insertAdjacentHTML('afterbegin', `<p>💡 App can be installed</div>`);

    event.preventDefault();
    installPrompt = event;
  })

  const installButton = sectionStart.querySelector('div#install_button');

  let installPrompt = undefined;

  installButton.addEventListener("click", async () => {
    if (!installPrompt) {
      console.log('No installPrompt...');
      return;
    }
    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
    disableInAppInstallPrompt();
  });

  function disableInAppInstallPrompt() {
    installPrompt = null;
    installButton.setAttribute("hidden", "");
  }

  window.addEventListener("appinstalled", () => {
    console.log('App installed, removing install button');
    sectionStart.querySelector('div#install_app').innerHTML = "✅ Installed";
  });
})
