import { themeSelector, setupThemeSwitcher } from "../ui_components/ar_theme_selector";

export function settingsBlockCallback() {
  setupThemeSwitcher();
}

export const settingsBlock = document.createElement("div");
settingsBlock.classList.add('block_wrap');
settingsBlock.setAttribute("id", "settingsBlock");
settingsBlock.innerHTML = `
  <div class="block" id="settings_block_appearance">
    <div class="subheader_area">
      <div class="subheader">Appearance</div>

      <div class='select_wrap'>
      </div>

    </div>
  </div>

  <div class="block" id="block_device_storage">
    <div class="subheader_area">
      <div class="subheader">Device Storage</div>
        <ul>
          <li>App cache</li>
          <li>Saved meditations</li>
          <li>Chats</li>
        </ul>
      </div>
    </div>
  </div>
  `;

settingsBlock
  .querySelector("div#settings_block_appearance div.select_wrap")
  .append(themeSelector);
