export function membershipPill(
  membershipLevelObj = {
    value: 'tier_public',
    label: 'No Account',
  }) {

  const membershipPill = document.createElement('div');
  membershipPill.classList.add('membership_level_pill');
  membershipPill.dataset.membershipLevel = membershipLevelObj.value;
  membershipPill.innerHTML = membershipLevelObj.label;

  return membershipPill;
}
