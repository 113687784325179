import * as app from "../ar_pwa.js";

// Display Development or Production

// Functions
document.addEventListener("DOMContentLoaded", function resizeViewPort() {
  const membershipViewport = document.getElementById("app_viewport");
  const storeViewportInitialHeight =
    getComputedStyle(membershipViewport).height;
  // console.log("storeViewportInitialHeight", storeViewportInitialHeight);
  // console.log("window.innerHeight: ", window.innerHeight + "px");

  // Test code to see if we can dynamically resize the viewport when
  // virtual keyboard appears... not working so far.
  // window.addEventListener('resize', () => {
  //     // For the rare legacy browsers that don't support it
  //     if (!window.visualViewport) {
  //       return
  //     }
  //     membershipViewport.css('height', `calc(${window.visualViewport.height}px - var(--safe-area-insert-top) - var(--safe-area-insert-bottom) - var(--keyboard-inset-height))`);
  //     console.log('VH: ' , window.visualViewport.height);
  // })
});

(function appMode() {
  if (isInAppMode()) {
    // Adjust CSS properties or perform other actions for app mode
    document.documentElement.classList.add("app-mode");
  } else {
    document.documentElement.classList.add("browser-mode");
  }
})();

export function isInAppMode() {
  // For iOS Safari
  if (window.navigator.standalone) {
    return true;
  }
  // For Android and other browsers that support "display-mode"
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }
  // Default to false if neither condition is met
  return false;
}
