import { icons } from "../ui_general/icons"
import { createMenuWidget } from "./menu_widget";
import { notifySwitchToSection } from "./membership_menu";

export function slidePane(
  {
    direction = "side",
    element,
    title,
    dataset,
    callback,
    target,
  }) {

  if (document.querySelector(`div.pane_wrap[data-pane-id='${generatePaneId(dataset)}']`)) {
    return null;
  }

  console.log('dataset', dataset)

  // Create Pane DOM Elements
  const paneContainer = createPaneContainer(title, element, direction, null, dataset.favourite);
  const paneHeader = paneContainer.querySelector('div.header');
  paneHeader.append(createCloseButton(direction));
  const paneWrap = createPaneWrap(paneContainer, direction, dataset);

  if (dataset?.meditationId) {
    paneHeader.appendChild(createMenuWidget('meditation', dataset, target, 'horizontal'))
    paneContainer.querySelector('iconify-icon').setAttribute('icon', icons.lotusBold)
  }

  // Attach event listeners
  setupTouchPrevention(paneWrap);
  setupDraggablePaneHeader(paneHeader, paneContainer);
  setupPaneDirection(paneWrap, direction);
  appendPaneToViewport(paneWrap, direction, callback);

  function closeThePane() {
    cleanUpPaneContainerStyle(paneContainer, direction);
    collapsePane(paneWrap);
    removePane(paneWrap);
    notifySwitchToSection();
  }

  function keyClosePane(event) {
    if (event.key === "Escape") closeThePane();
  }

  return paneWrap;

  // Utility Functions
  function createPaneContainer(title, contents, direction = 'side', icon, favourite = false) {
    const paneContainer = document.createElement("div");
    paneContainer.classList.add("pane_container");
    paneContainer.dataset.favourite = favourite;
    paneContainer.innerHTML = `
      <div class='header'>
        <div class='pane_title'>
          <iconify-icon noobserver icon='${icon}' class='header'></iconify-icon>
          <span>${title}</span>
        </div>
      </div>
      <div class='contents'></div>
    `;

    insertPaneContents(paneContainer.querySelector("div.contents"), contents);
    return paneContainer;
  }

  function createPaneWrap(container, direction, dataset) {
    const wrap = document.createElement("div");
    wrap.classList.add("pane_wrap", direction);
    wrap.dataset.paneId = generatePaneId(dataset);
    wrap.appendChild(container);
    return wrap;
  }

  function insertPaneContents(target, contents) {
    if (Array.isArray(contents)) {
      contents.forEach(content => insertPaneContents(target, content));
    } else if (contents instanceof HTMLElement) {
      target.appendChild(contents);
    } else {
      target.insertAdjacentHTML("beforeend", contents);
    }
  }

  function createCloseButton(direction) {
    const closeButton = document.createElement("div");
    closeButton.classList.add('pane_close');
    closeButton.onclick = closeThePane;

    const closeIcon = direction === "side" ? icons.arrowLeft : icons.close;
    closeButton.innerHTML = `<iconify-icon noobserver icon="${closeIcon}" class='close'></iconify-icon>`;

    return closeButton;
  }

  function setupPaneDirection(paneWrap, direction) {
    if (direction === "vertical") {
      ensureClosableByTappingOutside(paneWrap);
    }
  }

  function ensureClosableByTappingOutside(paneWrap) {
    ['mouseup', 'touchend'].forEach(eventType => {
      paneWrap.addEventListener(eventType, event => {
        if (event.target === paneWrap) {
          event.preventDefault();
          event.stopPropagation();
          closeThePane();
        }
      });
    });
  }

  function setupTouchPrevention(paneWrap) {
    paneWrap.addEventListener('touchstart', function preventIosBackNavigation(event) {

      paneWrap.addEventListener('touchmove', handleMove, { passive: false });
      paneWrap.addEventListener('touchend', () => {
        paneWrap.removeEventListener('touchmove', handleMove);
      });

      function handleMove(event) {
        // Cancel meditationPlayer interactions from propagating
        if (event.target.nodeName === 'MEDITATION-PLAYER') {
          event.preventDefault();
        }

        // Prevent back-swipe
        const SWIPE_ACTIVE_AREA = 60;
        const startX = event.touches[0].pageX;
        const startY = event.touches[0].pageY
        const moveX = event.touches[0].pageX - startX;
        const moveY = event.touches[0].pageY - startY;
        if (startX < SWIPE_ACTIVE_AREA && Math.abs(moveX) > Math.abs(moveY) * 1.5) {
          event.preventDefault();
        }
      }
    });
  }

  function setupDraggablePaneHeader(dragPoint, dragItem) {
    interact(dragPoint).draggable({
      listeners: createDragListeners(dragPoint, dragItem)
    }).styleCursor(false);
  }

  function createDragListeners(dragPoint, dragItem) {
    const viewportHeight = window.viewPort.getBoundingClientRect().height;
    const lowerBound = viewportHeight / 2;
    const paneContents = paneContainer.querySelector('div.contents');
    const paneContentsInitialHeight = window.getComputedStyle(paneContents).height;
    let panePositionY;
    let panePositionYInitial;
    let storedTransitionValue;

    dragItem.addEventListener('transitionend', () => {
      panePositionYInitial = dragItem.getBoundingClientRect().y;
    });

    return {
      start(event) {
        // console.log('start drag', event);
        // console.log('target', event.target);

        // return if target is div.contents
        if (!event.target.closest('div.header')) {
          console.log('return?')
          return false; // Prevent drag from starting if not on header
        }

        panePositionY = dragItem.getBoundingClientRect().y;
        storedTransitionValue = window.getComputedStyle(dragItem).transition;
        dragItem.style.transition = "none";

        // paneContents needs to be resizable instantly without transition delay
        paneContents.style.transition = "";
      },
      move(event) {
        /**
          Move the pane
        **/
        panePositionY += event.dy;
        dragItem.style.transform = `translateY(${panePositionY}px)`;

        /**
          Resize the paneContents in proportion to the movement
        **/
        const paneContentsCurrentSize = parseFloat(window.getComputedStyle(paneContents)
          .height
          .replace("px", ""));
        const paneContentsResize = paneContentsCurrentSize - event.dy;

        // Stop resizing when the *drag gesture* goes over the top of the viewport
        if (paneContainer.getBoundingClientRect().top >= 0) {
          paneContents.style.height = `${paneContentsResize}px`;
        }

        // Prevent dragging *element itself* over top of viewport
        if (panePositionY <= 0) {
          dragItem.style.transform = `translateY(0px)`;
        }
      },
      end(event) {
        if (panePositionY > lowerBound) {
          closeThePane();
        } else {
          resetPanePosition(dragItem, storedTransitionValue, panePositionYInitial);

          // transition animation enabled for height to be restored gracefully
          paneContents.style.transition = "height 0.25s ease";
          paneContents.style.height = paneContentsInitialHeight;
        }
      }
    };
  }

  function appendPaneToViewport(paneWrap, direction = 'side', callback) {
    const viewPort = document.querySelector("div#app_viewport");
    viewPort.appendChild(paneWrap);

    setTimeout(() => {
      if (direction === 'vertical') {
        document.querySelector("section:not(.hidden)").classList.add("move_back");
      }
      paneWrap.classList.add('show');
      document.addEventListener('keyup', keyClosePane);
      if (callback?.open) callback.open();

    }, 50);
  }

  function cleanUpPaneContainerStyle(container, direction = 'side') {
    if (direction === 'vertical') {
      container.style.transform = "translateY(100vh)";
    }
    container.style.transition = "";
  }

  function collapsePane(paneWrap) {
    paneWrap.classList.remove('show');
    document.querySelector("section:not(.hidden)").classList.remove('move_back');
  }

  function removePane(paneWrap) {
    setTimeout(() => {
      if (paneWrap) {
        if (callback?.close) callback.close();
        paneWrap.remove();
        document.removeEventListener("keyup", keyClosePane);
      }
    }, 400);
  }

  function resetPanePosition(container, transitionValue, initialY) {
    container.style.transition = `${transitionValue}`;
    container.style.transform = `translateY(${initialY}px)`;
  }

}

export function generatePaneId(dataset) {
  if (!dataset) return "no_pane_id";

  let paneId;

  if (dataset.menuItem) {
    paneId = dataset.menuItem.label;
  } else {
    const title = dataset.title
      .replaceAll(`'`, '')
      .replaceAll(`"`, '');
    paneId = `${dataset.postId}.${title}`;
  }

  return paneId;
}
