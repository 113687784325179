import { registerTheUser } from "../restapi/restapi_register_user";
import { logTheUserIn } from "../restapi/restapi_login_user";
import { icons } from "../ui_general/icons";

export const registerForm = document.createElement('div');
registerForm.setAttribute('id', 'register_box');
registerForm.innerHTML = `
  `;

// Components
// Button for Login
export const registerButton = document.createElement('button-std');
registerButton.setAttribute("id", "register_submit");
registerButton.setAttribute("hue", 210);
registerButton.setAttribute("icon", icons.register);
registerButton.textContent = "Register";

// Input for Email
export const emailForm = document.createElement('input');
emailForm.setAttribute("type", "text");
emailForm.setAttribute("id", "register_email");
emailForm.setAttribute("placeholder", "Username or email");
emailForm.setAttribute("autocomplete", "email");

// Input for Password
export const passwordForm = document.createElement('input');
passwordForm.setAttribute("type", "password");
passwordForm.setAttribute("id", "register_password");
passwordForm.setAttribute("placeholder", "Password");
passwordForm.setAttribute("autocomplete", "current-password")

export const registerStatusText = document.createElement('div');
registerStatusText.setAttribute('id', 'register_status_text');

registerForm.append(
  emailForm,
  passwordForm,
  registerButton,
  registerStatusText
);

registerButton.addEventListener('click', () => {
  registerStatusText.innerText = "Registering...";

  // Store it here so we can reuse it to log the user in incase they
  // remove it from the password field before we can access it again.
  const passwordValue = passwordForm.value;

  registerTheUser(emailForm.value, passwordForm.value)
    .then(response => {
      if (response.status === 'user_registered') {
        console.log('status ok');
        /**

          Registration was successful. We log the user in so it is convenient
          for them going forward.

        **/
        registerStatusText.innerText = '✅ Registration successful! Logging you in...';

        logTheUserIn(response.email, passwordValue)
          .then(response => {
            if (response.data.status === 200) {
              console.log('LOGGED IN');
              // switch to login tab when finished
            }
          })



      }
    })
});

export function keyEnterRegister(event) {
  if (event.key == "Enter") {
    registerButton.click();
  }
}

emailForm.addEventListener('keypress', (event) => keyEnterRegister(event));
passwordForm.addEventListener('keypress', (event) => keyEnterRegister(event));
