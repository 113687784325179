export const AppDB = "AR_App";
export const DB_VER = 14;
export const appTitle = "Attachment Repair Membership";

window.viewPort = document.querySelector("div#app_viewport");

export const domain = {
  protocol: window.location.protocol, // http | https
  hostname: window.location.hostname, // eg. https://attachmentrepair.com
  host: window.location.host, // hostname plus :port
}
// console.log(domain.protocol, domain.hostname, domain.host)

export const restApiHostUrl = 'https://attachmentrepair.com'; //import.meta.env.VITE_HOST_URL;

export const arURL = {
  utm_source: "ar_app",
}

export function getEnvironment() {
  if (domain.host === "attachmentrepair.com") {
    return "Production";
  } else {
    return "Development";
  }
}

// App functions
export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

// Init functions
export function touchLogging() {
  if (settings.touchLogging) {
    // Debugging Functions
    window.addEventListener("touchstart", (event) => {
      const touchedElement = event.target;
      console.log("Touched:", touchedElement, event.target);
    });
  }
};

export const appState = {
  chatInit: false,
  journalInit: false,
  loginToken: Boolean(localStorage.getItem("AR_Userdata_JWT")),
}

export const meditationTaxonomies = {
  purpose: {
    enabled: true,
    input: "input",
    order: 1,
    label: "I want to work on...",
    name: "Purpose",
    name_short: "Purpose",
    type: "taxonomy"
  },
  issue_targeted: {
    enabled: true,
    input: "select",
    order: 2,
    label: "In particular...",
    name: "Issue Targeted",
    name_short: "Issue",
    type: "taxonomy"
  },
  techniques: {
    enabled: false,
    input: "select",
    order: 3,
    name: "Method",
    type: "taxonomy"
  },
  schemas: {
    enabled: false,
    input: undefined,
    type: "taxonomy"
  },
  inner_critics: {
    enabled: false,
    input: undefined,
    type: "taxonomy"
  },
  modes: {
    enabled: false,
    input: undefined,
    type: "taxonomy"
  },
  attachment_styles: {
    enabled: false,
    input: undefined,
    type: "taxonomy"
  },
  dmm_types: {
    enabled: false,
    input: undefined,
    type: "taxonomy"
  },
}

export const enabledMeditationTaxonomies = Object.entries(meditationTaxonomies)
  .filter(([key, value]) => value.enabled)
  .map(([key]) => key);

export const settings = {
  debugBox: true,
  touchLogging: false,
  meditation: {
    options: {
      backSeekOptions: [5, 15],
    },
    filters: {
      purpose: {
        enabled: true,
        input: "input",
        order: 1,
        name: "I want to work on...",
        type: "taxonomy"
      },
      issue_targeted: {
        enabled: true,
        input: "select",
        order: 2,
        name: "In particular...",
        type: "taxonomy"
      },
      techniques: {
        enabled: false,
        input: "select",
        order: 3,
        name: "Method",
        type: "taxonomy"
      },
      duration: {
        enabled: true,
        input: "radio",
        order: 3,
        name: "Duration",
      },
      title: {
        enabled: false,
        input: undefined,
      },
      schemas: {
        enabled: false,
        input: undefined,
        type: "taxonomy"
      },
      inner_critics: {
        enabled: false,
        input: undefined,
        type: "taxonomy"
      },
      modes: {
        enabled: false,
        input: undefined,
        type: "taxonomy"
      },
      attachment_styles: {
        enabled: false,
        input: undefined,
        type: "taxonomy"
      },
      dmm_types: {
        enabled: false,
        input: undefined,
        type: "taxonomy"
      },
      membership_level: {
        enabled: false,
        input: undefined,
      },
    },
  },
};

export const enabledMeditationFilters = Object.entries(settings.meditation.filters)
  .filter(([key, value]) => value.enabled)
  .map(([key]) => key);

// console.info('enabledMeditationFilters', enabledMeditationFilters)

export const userDataObj = {
  profile: {
    nickname: undefined,
    birthday: undefined,
    gender: undefined,
    bio: undefined,
    assessments: {
      ASA: {},
      ECR48: {},
    },
    psychology: {
      attachment_style: undefined,
      dmm_style: undefined,
      schemas: {
        1: true,
        2: false,
      },
    },
  },
  account: {
    membership_tier: {
      value: "free_tier",
      label: "No Account",
    },
    isLoggedIn: false,
  },
  settings: {
    notifications: {},
    appearance: undefined,
  },
  meditation: {
    recentlyListened: [],
    favourites: [],
    listened: {},
  },
};

export const meditationPlayer = {}
