import "iconify-icon";
import { icons } from "../ui_general/icons";

customElements.define(
  "completion-bar",
  class CompletionBar extends HTMLElement {
    static get observedAttributes() {
      return ["color", "size", "label", "icon", "onclick"]; // Add 'icon' and 'onclick' to the list
    }

    constructor() {
      super();

      this.attachShadow({ mode: "open" });
      this.shadowRoot.innerHTML = `
				<style>
				  div.completion_bar {
						--height: 30px;
					  --width: 80px;
					  --bg-colour: hsla(217, 100%, 78%, 0.75);

					  display: flex;
						flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            column-gap: 8px;

						div.bar_wrap {
    				  width: 100%;
              display: block;
              background: hsla(217, 100%, 78%, 0.27);
              height: 8px;
              border-radius: 4px;
              overflow: hidden;

						  div.bar_element {
								border-right: 3px solid rgba(151, 238, 229, 0.57);
								background-color: rgb(106, 214, 202);
								height: 100%;
								width: 10%;
							}
						}

						iconify-icon.completion {
						  color: hsla(217, 100%, 78%, 0.47);
							font-size: 26px;
						}
				  }
				</style>

	      <div class='completion_bar off'>
					<div class='bar_wrap'>
					 <div class='bar_element'></div>
					</div>
	        <iconify-icon noobserver icon='${icons.checkOff}' class='completion'></iconify-icon>
	      </div>
	      `;

      // Create a MutationObserver to observe attribute changes
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "attributes") {
            // this.syncAttribute(mutation.attributeName);
          }
        });
      });

      // Start observing the custom element for attribute changes
      observer.observe(this, { attributes: true });
    }

    connectedCallback() {
      setTimeout(() => {
        this.shadowRoot
          .querySelector("div.completion_bar")
          .classList.add("fade_in");
      }, 0.01);

      // check if spc_started, if so remove the 'off' class

      // get spcData obj from LS.
      // check isStarted value.
    }

    disconnectedCallback() {
      // console.log("LoadingSpinner removed from page.");
    }

    adoptedCallback() {
      // console.log("LoadingSpinner moved to new page.");
    }
  },
);
