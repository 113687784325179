import * as ar from "../ui_general/ar_globals.js";

export function initializeIndexedDB() {
  // console.info("🗄️ initializeIndexedDB() ver:", ar.DB_VER);

  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open(ar.AppDB, ar.DB_VER);

    dbRequest.onupgradeneeded = function (event) {
      console.log("🆙 dbRequest.onupgradedneeded");

      const db = event.target.result;

      // Developer can manually delete a store by setting it here.
      // It will delete the locally stored user data for that particular key.
      // For testing only.
      const resetObjectStores = {
        meditationData: false,
        userData: false,
        journalData: false,
        chatInstanceData: false,
      };

      if (Object.values(resetObjectStores).some((value) => value === true)) {
        for (storeName in resetObjectStores) {
          // console.log("🗄️ storeName:", storeName);
          if (resetObjectStores[storeName] === true) {
            // console.log("🗄️  true: ", storeName);

            if (!db.objectStoreNames.contains(storeName)) {
              // console.info(
              //   `🗄️  ❗❗❗ Deleting outdated IDB store: ${storeName} ❗❗❗`,
              // );
              db.deleteObjectStore(storeName);
            }
          }
        }
      }

      // create Object Stores
      // ====================

      // User Data
      if (!db.objectStoreNames.contains("userData")) {
        // console.info("Creating new IndexedDB Object store: userData");
        const userDataStore = db.createObjectStore("userData", {
          keyPath: "userID",
        });
      }

      // Chat
      if (!db.objectStoreNames.contains("chatInstanceData")) {
        // console.info("Creating new IndexedDB Object store: chatInstanceData");

        const chatInstanceStore = db.createObjectStore("chatInstanceData", {
          keyPath: "chatID",
        });
        chatInstanceStore.createIndex("by_chatName", "chatName", {
          unique: false,
        });
        chatInstanceStore.createIndex("by_character", "character", {
          unique: false,
        });
      }

      // Meditation Data store creation
      if (!db.objectStoreNames.contains("meditationData")) {
        console.info("Creating new IndexedDB Object store: meditationData");
        const meditationInstanceStore = db.createObjectStore("meditationData", {
          keyPath: "meditationId",
        });
        meditationInstanceStore.createIndex("by_postID", "postId", {
          unique: false,
        });
        meditationInstanceStore.createIndex("is_favourite", "favourite", {
          unique: false,
        });
        meditationInstanceStore.createIndex("is_offline", "offline", {
          unique: false,
        });
      }

      // Journal
      if (!db.objectStoreNames.contains("journalData")) {
        console.info("Creating new IndexedDB Object store: journalData");

        const journalInstanceStore = db.createObjectStore("journalData", {
          keyPath: "journalID",
        });
        journalInstanceStore.createIndex("by_journalName", "journalName", {
          unique: false,
        });
        journalInstanceStore.createIndex("by_character", "character", {
          unique: false,
        });
      }
    };

    dbRequest.onsuccess = function (event) {
      resolve(event.target.result);
    };

    dbRequest.onerror = function (event) {
      reject(`initializeIndexedDB() error: ${event.target.errorCode}`);
    };
  });
}

// if (!window.hasInitialisedPWA) {
//   initializeIndexedDB()
//     .then((db) => {
//       console.info("🗄️ Database initialized", db);
//     })
//     .catch((error) => {
//       console.error("🗄️ Failed to initialize database", error);
//     });
// }

export let dbPromise;
export function getDB() {
  if (!dbPromise) {
    dbPromise = initializeIndexedDB();
  }
  return dbPromise;
}

// IndexedDB
export function saveToIndexedDB({ storeName, actionType, data }) {
  // console.log("🗄️ saveToIndexedDB(): storeName", storeName, "actionType", actionType, "data", data);

  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open(ar.AppDB, ar.DB_VER);

    dbRequest.onerror = function (event) {
      reject(`IndexedDB open error: ${event.target.errorCode}`);
    };

    dbRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);

      let request;
      if (storeName === "chatInstanceData") {
        // Custom chat handling logic
        switch (actionType) {
          case "create":
            request = store.add(data);
            break;
          case "update":
            data.forEach((chatInstanceData) => {
              if (chatInstanceData.deleted) {
                request = store.delete(chatInstanceData.chatID); // delete chat
              } else {
                request = store.put(chatInstanceData); // update chat
              }
            });
            break;
          case "delete":
            request = store.delete(data.chatID);
            break;
          default:
            console.error("Unknown action type");
            reject("Unknown action type for saving to IndexedDB");
            return;
        }
      } else if (storeName === "meditationData") {

        // Clear it before updating it with the list of current data
        store.clear().onsuccess = () => {
          // console.log("All records cleared and ready to add new ones.");
          let requests = Object.values(data).map((meditationData) => {
            // console.log(
            //   " - IDB Save: ",
            //   meditationData.meditationId,
            //   meditationData.title,
            //   // meditationData,
            // );

            return new Promise((resolve, reject) => {
              request = store.put(meditationData);
              request.onsuccess = () =>
                resolve(
                  "meditationData " +
                  meditationData.meditationId +
                  " synced successfully!",
                );
              request.onerror = (event) =>
                reject(
                  `Sync error for ${meditationData.meditationlId}: ${event.target.error}`,
                );
            });
          });

          Promise.all(requests)
            .then((results) => resolve("All data synced successfully!"))
            .catch((error) => reject("Data sync failed: " + error));
        };

        transaction.onerror = (event) => {
          console.error("Transaction failed", event.target.error);
          reject("Transaction error: " + event.target.error);
        };
      } else {
        // Generic handling for other types of data
        switch (actionType) {
          case "create":
            request = store.add(data);
            break;
          case "update":
            request = store.put(data);
            break;
          case "delete":
            request = store.delete(data.id); // Assuming 'data' has an 'id' key for deletion
            break;
          default:
            console.error("Unknown action type");
            reject("Unknown action type for saving to IndexedDB");
            return;
        }
      }

      if (request) {
        request.onsuccess = () =>
          resolve(`${actionType} action performed successfully!`);
        request.onerror = (event) =>
          reject(`Error during ${actionType} action: ${event.target.error}`);
      }
    };
  });
}
