import { sectionStart } from "../sections/section_start";

if ('serviceWorker' in navigator) {
  const serviceWorkerSupported = '✅ Service Worker is supported';
  console.log(serviceWorkerSupported);
  sectionStart.querySelector('div#install_app').insertAdjacentHTML('beforeend', `<div>${serviceWorkerSupported}</div>`);
} else {
  const serviceWorkerNotSupported = '❌ Service Worker not supported';
  console.log(serviceWorkerNotSupported);
  sectionStart.querySelector('div#install_app').insertAdjacentHTML('beforeend', `<div>${serviceWorkerNotSupported}</div>`);
}
