import { linkToSPCPaneBlock } from "../blocks/spcViewBlock";
import { membershipPill } from "./membership_pill";

export function createSPCRow(spcPost) {
  const spcRow = document.createElement('div');
  spcRow.classList.add('spc_row', 'horizontal', 'grid');
  spcRow.dataset['canAccess'] = spcPost.canAccess;
  spcRow.dataset['membershipLevel'] = spcPost.membershipLevel;
  let files_summary = "";

  if (spcPost.includedModules) {
    const files_count = spcPost.includedModules.reduce((acc, module) => {
      if (module.files) {
        module.files.forEach(file => {
          if (file.acf_fc_layout === "lecture") {
            acc["lectures"] += 1;
          } else if (file.acf_fc_layout === "meditation") {
            acc["meditations"] += 1;
          }
        })
      }
      return acc;
    }, { "lectures": 0, "meditations": 0 });

    spcRow.dataset['filesLectures'] = files_count['lectures'];
    spcRow.dataset['filesMeditations'] = files_count['meditations'];

    files_summary = `
      <div class='files_summary'>
        <div class='lecture_count'>${files_count['lectures']} Lectures</div>
        <div class='meditation_count'>${files_count['meditations']} Meditations</div>
      </div>
    `
    // console.log('files_count', files_count)
  }

  spcRow.innerHTML = `
    <img class='featured' src='${spcPost.images.spc_image_url_full}' />
    <div class='title'>${spcPost.title}</div>
    ${files_summary}
    `;

  spcRow.append(membershipPill(spcPost.membershipLevel))

  if (spcPost.canAccess) {
    spcRow.insertAdjacentHTML('beforeend', '<completion-bar></completion-bar>');
  }

  spcRow.addEventListener("click", () => {
    linkToSPCPaneBlock(spcPost)
  });

  return spcRow
}
