import * as ar from "../ui_general/ar_globals.js";
import * as db from "../database/indexdb.js";

export class MeditationData {
  constructor() {
    const handler = {
      set: (obj, prop, value) => {
        // console.log(`Property ${prop} changed from ${obj[prop]} to ${value}`);
        obj[prop] = value;
        // additional custom logic could go here
        return true; // indicates the property was successfully set
      }
    };

    // each key will be 'id'. For now, this corresponds to meditationId, but in the future it will be meditationId.
    this.data = new Proxy({}, handler)
  }

  // Favourites
  addFavourite({ meditationId: meditationId, dataset: dataset }) {
    dataset.favourite = true;
    // console.log('addFavourite() dataset ->', { ...dataset })
    this.data[meditationId] = { ...dataset };
    // console.log("this.data ->", this.data);
    this.watchFavourite();

    document
      .querySelectorAll(`div.meditation_row[data-meditation-id='${meditationId}']`)
      .forEach((row) => {
        row.dataset.favourite = true;
        row
          .querySelector("div.menu_widget")
          ._tippy.popper.querySelector("div.menu_item.label_favourite")
          .classList.add("on");
      });
  }

  removeFavourite({ meditationId: meditationId, dataset: dataset } = {}) {
    const keyToRemove = Object.keys(this.data).find(
      (key) => Number(this.data[key].meditationId) === Number(meditationId),
    );
    if (keyToRemove) delete this.data[keyToRemove];
    // console.log("removeFavourite() data:", meditationId, this.data);

    document
      .querySelectorAll(`div.meditation_row[data-meditation-id='${meditationId}']`)
      .forEach((row) => {
        delete row.dataset.favourite;
        row
          .querySelector("div.menu_widget")
          ._tippy.popper.querySelector("div.menu_item.label_favourite")
          .classList.remove("on");
      });
    this.watchFavourite();
  }

  watchFavourite() {
    this.syncMeditationDataToDB();

    const favUpdateEvent = new CustomEvent("favUpdateEvent", {
      bubbles: true,
      composed: true,
    });

    document.dispatchEvent(favUpdateEvent);
  }

  checkFavourite(id) {
    const favTrue = Object.values(this.data).some(
      (meditationObj) => Number(meditationObj.meditationId) === Number(id),
    );
    // console.log("checkfav", id, favTrue);
    return favTrue;
  }

  // Listens
  updateListen(meditationId, timestamp) {
    if (!this.data.listened[meditationId]) {
      this.data.listened[meditationId] = [];
    }
    this.data.listened[meditationId].push(timestamp);
  }

  // Sync
  syncMeditationDataToDB() {
    // console.log("syncMeditationDataToDB", this.data);

    // Check if there is data to sync
    if (Object.keys(this.data).length === 0) {
      return;
    }
    db.saveToIndexedDB({ storeName: "meditationData", actionType: "create", data: this.data })
  }

  async loadMeditationsFromDB() {
    return fetchMeditationsFromIDB().then((result) => {
      // console.log("loadMeditationsFromDB", result);
      if (Object.keys(result).length > 0) {
        for (let meditation in result) {
          this.data[result[meditation].meditationId] = result[meditation];
        }
      }
      return result;
    });
    // if IDBMeditations.length, for each, load into meditationUserData
  }
}

export function fetchMeditationsFromIDB() {
  return db.getDB().then(db => {
    return new Promise((resolve, reject) => {
      const dbRequest = indexedDB.open(ar.AppDB, ar.DB_VER);

      dbRequest.onerror = function (event) {
        reject("IndexedDB error: " + event.target.errorCode);
      };

      dbRequest.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction("meditationData", "readonly");
        const store = transaction.objectStore("meditationData");
        const getAllRequest = store.getAll();

        getAllRequest.onerror = function (event) {
          reject("Could not retrieve data from IndexedDB:", event.target.error);
        };

        getAllRequest.onsuccess = function (event) {
          resolve(event.target.result); // This will be an array of chat records
        };
      };

      // No need for onupgradeneeded here since we're only reading data
    });
  }).catch(error => console.error("nope", error));
}
