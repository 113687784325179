import { billingBlock, billingBlockCallback } from "../blocks/billingBlock.js";
import { icons } from "../ui_general/icons.js";
import { slidePane } from "./slidePane.js";

export function printUpgradeButton(postData) {
  const lockIcon = `<iconify-icon noobserver class='noaccess' icon="${icons.padlock}"></iconify-icon>`;
  const upgradeButton = document.createElement('div');

  upgradeButton.classList.add('upgradeButton');
  upgradeButton.dataset.membershipLevel = postData.membershipLevel?.value ?? "";

  upgradeButton.innerHTML = `${lockIcon} Available in ${postData.membershipLevel?.label} subscription`;
  upgradeButton.addEventListener("click", () => {
    slidePane(
      {
        element: billingBlock,
        title: 'Membership',
        callback: billingBlockCallback,
      }
    );
  });
  return upgradeButton;
}
