import { sectionMeditation, libraryMain, filterButton, meditationLibraryState, uiGetMeditationPosts } from "../sections/section_meditation"
// import { sectionMeditation } from "../sections/section_meditation";
import { enabledMeditationFilters, settings } from "../ui_general/ar_globals";
import { initialiseTippyOn } from "../ui_general/tippy";
import { terms_purpose } from "../taxonomies/purpose";
import { terms_techniques } from "../taxonomies/techniques";
import { terms_issue_targeted } from "../taxonomies/issue_targeted";
export const libraryFilter = document.createElement("div");
libraryFilter.id = "libraryFilter";
libraryFilter.innerHTML = `

  <div class="taxonomy_filters">

    <div class="filter_group" data-taxonomy="duration">
      <div class="filter_name">Duration</div>
        <fieldset id='taxonomy_duration'>
          <label data-taxonomy-duration='10'>
            <input type='radio' name='duration' value='' checked />
            <div class='label'>Any</div>
          </label>
          <label data-taxonomy-duration='10'>
            <input type='radio' name='duration' value='10' />
            <div class='label'>~ 10 mins</div>
          </label>
          <label data-taxonomy-duration='20'>
            <input type='radio' name='duration' value='20' />
            <div class='label'>20 mins</div>
          </label>
          <label data-taxonomy-duration='30'>
            <input type='radio' name='duration' value='30' />
            <div class='label'>+ 30 mins</div>
          </label>
        </fieldset>
      </div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_widget">
        <div class="filter_name">Date Recorded</div>
        <!-- toggle switch before/after -->
        <!-- date input field calendar -->
        <div class="radio_selector">
          <input type="radio" id="before" name="date_selector" value="before">
          <label for="before">Before</label>
          <input type="radio" id="after" name="date_selector" value="after">
          <label for="after">After</label>
        </div>
        <input type="date">
      </div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Online-Event</div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Self-Paced Course</div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Attachment Style</div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Schema</div>
    </div>

    <div class='button_wrap'>
      <button-std size='normal' id='clear_filter' type='clear' icon='iconamoon:unavailable-bold'></button-std>
      <button-std size='normal' id='apply_filter'icon='iconamoon:funnel-fill' >Apply</button-std>
    </div>

  </div>

  `;

// Build out the input forms
// 1. Loop over enabledMeditationFilters
// 2. Check the type, build a form depending on the type
// 3. Append result into libraryFilter.querySelector("div.container)

enabledMeditationFilters.reverse().forEach(taxonomyName => {

  const formContainer = document.createElement("div");
  formContainer.classList.add("filter_group", "topicselector");

  const taxNameCapitalized = taxonomyName.charAt(0).toUpperCase() + taxonomyName.slice(1);
  formContainer.dataset.taxonomy = taxonomyName;
  formContainer.innerHTML = `<div class="filter_name">${settings.meditation.filters[taxonomyName].name}</div>`

  // Build one or another type of form depending on the taxonomy
  const controlType = settings.meditation.filters[taxonomyName].input;
  // console.log('taxName ', taxonomyName)
  if (controlType === "input") {
    buildFilterFormRadio(taxonomyName).forEach(input => {
      formContainer.appendChild(input)
    })
  } else if (controlType === "select") {
    formContainer.appendChild(buildFilterFormSelect(taxonomyName));
  }

  if (taxonomyName !== "duration") {
    libraryFilter.querySelector("div.taxonomy_filters").insertAdjacentElement('afterbegin', formContainer);
  }
})



function buildFilterFormSelect(taxonomyName) {
  // Setup selectWrap
  const selectWrap = document.createElement("div");
  selectWrap.classList.add("select_wrap");
  selectWrap.innerHTML = `
    <select name="${taxonomyName}" id="${taxonomyName}">
    </select>
  `;

  let tempTerms;
  switch (taxonomyName) {
    case "issue_targeted":
      tempTerms = terms_issue_targeted;
      break;
    case "techniques":
      tempTerms = terms_techniques;
      break;
  };

  // Generate tempTerms quickly:
  // `jq '[[[.[] | {name, slug, count, description}]]]' terms.json`

  // Add default "Any" term
  tempTerms = addDefaultTerm(tempTerms);

  // Loop over each item, build out...
  tempTerms.forEach(term => {
    if (term.count === 0) return
    // Filter <select> options block
    const termCount = term.name !== "Any"
      ? `(${term.count})`
      : "";
    selectWrap.querySelector("select").innerHTML += `
        <option value="${term.slug}">${term.name} ${termCount}</option>
      `;
  });

  return selectWrap
}

function buildFilterFormRadio(taxonomyName) {
  // call the restAPI here, get list of terms
  let tempTerms = terms_purpose;

  // Generate tempTerms quickly:
  // `jq '[[[.[] | {name, slug, count, description}]]]' purpose.json`

  tempTerms = addDefaultTerm(tempTerms);

  // Loop over each item, build out...
  const inputs = []
  Object.values(tempTerms).forEach(term => {

    if (term.count === 0) return;

    // Filter input radio block
    const topicDiv = document.createElement("div");

    // todo: [] This should be abstracted better into the function that gives "any".
    let termDefault;
    let termSlug;
    let termValue;
    if (term.name === "Any") {
      termDefault = "checked";
      termSlug = "any";
      termValue = "";
    } else {
      termDefault = "";
      termSlug = term.slug
      termValue = term.slug
    }

    topicDiv.classList.add("topic", termSlug);
    topicDiv.innerHTML = `
        <label data-taxonomy-${taxonomyName}="${termValue}">
          <div class="label">${term.name}</div>
          <input type="radio" name="${taxonomyName}" value="${termValue}" ${termDefault}>
        </label>
      `;

    // Description
    if (term.slug !== "") {
      const descriptionButton = document.createElement("div");
      descriptionButton.classList.add("description");
      descriptionButton.innerHTML = "i";
      initialiseTippyOn({
        element: descriptionButton,
        theme: "library_filter_description",
        placement: "top",
        content: term.description,
        allowHTML: true,
        // offset: [0, 10],
      })
      topicDiv.appendChild(descriptionButton);
    }

    // Save it
    inputs.push(topicDiv);
  });

  return inputs;

}

// Map Library Filter UI Controls to internal state of the libraryFilter object
enabledMeditationFilters.forEach((taxonomyName) => {
  const controlType = settings.meditation.filters[taxonomyName].input;

  libraryFilter
    .querySelectorAll(`div.filter_group[data-taxonomy='${taxonomyName}'] ${controlType}[name='${taxonomyName}']`)
    .forEach((element) => {
      element.addEventListener("change", (event) => {
        libraryFilter[taxonomyName] = event.target.value;
      })
    });
});

// Apply All
libraryFilter.querySelector('button-std#apply_filter').addEventListener("click", async () => {

  // close the tippy popup
  filterButton._tippy.hide();

  try {
    // Invoke the network request and wait for it to complete
    await uiGetMeditationPosts({
      page: 1,
      ...getLibraryFilterSettings()
    });

    // ButtonArrow.updateAllButtons();

  } catch (error) {
    console.error("Failed to fetch meditation posts:", error);
  } finally {
    meditationLibraryState.isLoading = false; // Reset loading state regardless of success or failure
  }

  // Save state into local storage (only after user confirms with Apply button)
  enabledMeditationFilters.forEach(taxonomyName => {
    localStorage.setItem(`AR_Userdata_Settings_libraryFilter_${taxonomyName}`, libraryFilter[taxonomyName]);
    // console.log(`localStorage (${taxonomyName}): set to `, libraryFilter[taxonomyName]);
  })

})

// Clear All
libraryFilter.querySelector('button-std#clear_filter').addEventListener("click", () => {
  enabledMeditationFilters.forEach(taxonomyName => libraryFilter[taxonomyName] = "");
})

// Library Filter Getters/Setters

Object.defineProperty(libraryFilter, 'duration', {
  get: function () {
    const durationSelected = this.querySelector("div.filter_group[data-taxonomy=duration] input:checked");
    const durationValue = durationSelected.value !== "any" ? durationSelected.value : "";
    return durationValue ? durationValue : "";
  },
  set: function (newValue) {
    const input = this.querySelector(`div.filter_group[data-taxonomy=duration] input[value="${newValue}"]`);
    if (input) {
      input.checked = true;
    }
    updateStatusDot();
  }

});
Object.defineProperty(libraryFilter, 'purpose', {
  get: function () {
    const purposeSelected = this.querySelector("div.filter_group[data-taxonomy=purpose] input:checked",
    );
    const purposeValue = purposeSelected.value !== "any" ? purposeSelected.value : "";
    return purposeValue ? purposeValue : "";
  },
  set: function (newValue) {
    const input = this.querySelector(`div.filter_group[data-taxonomy=purpose] input[value="${newValue}"]`);
    if (input) {
      input.checked = true;
    }
    updateStatusDot();
  }

});

Object.defineProperty(libraryFilter, 'techniques', {
  get: function () {
    const techniquesSelect = this.querySelector("div.filter_group[data-taxonomy=techniques] select",
    );
    const techniquesSelected = techniquesSelect.options[techniquesSelect.selectedIndex];
    const techniquesValue = techniquesSelected.value !== "any" ? techniquesSelected.value : "";
    return techniquesSelected ? techniquesValue : "";
  },
  set: function (newValue) {
    const input = this.querySelector(`div.filter_group[data-taxonomy=techniques] select`);
    if (input) {
      input.value = newValue;
    }
    updateStatusDot();
  }
});

Object.defineProperty(libraryFilter, 'issue_targeted', {
  get: function () {
    const issueSelect = this.querySelector("div.filter_group[data-taxonomy=issue_targeted] select",
    );
    const issueSelected = issueSelect.options[issueSelect.selectedIndex];
    const issueValue = issueSelected.value !== "any" ? issueSelected.value : "";
    return issueSelected ? issueValue : "";
  },
  set: function (newValue) {
    const input = this.querySelector(`div.filter_group[data-taxonomy=issue_targeted] select`);
    if (input) {
      input.value = newValue;
    }
    updateStatusDot();
  }
});

// Initialise
document.addEventListener("DOMContentLoaded", () => {
  // Load state out of local storage
  enabledMeditationFilters.forEach(taxonomyName => {
    const localStorageValue = localStorage.getItem(`AR_Userdata_Settings_libraryFilter_${taxonomyName}`);
    // console.log(`localStorageValue (${taxonomyName}): `, localStorageValue)
    if (localStorageValue) {
      libraryFilter[taxonomyName] = localStorageValue;
    }
  });
});

// Functions
export function getLibraryFilterSettings() {
  const libraryFilterSettings = enabledMeditationFilters.reduce((acc, taxonomyName) => {
    acc[taxonomyName] = libraryFilter[taxonomyName];
    return acc
  }, {});
  // console.log('getLibraryFilterSettings()', libraryFilterSettings)
  return libraryFilterSettings;
}

// Universal update function for status dot
function updateStatusDot() {
  const statusDot = sectionMeditation.querySelector('div#library_filter.action_button div.status_dot');
  const checkFilter = Object.entries(getLibraryFilterSettings()).some(([key, value]) => value);
  // console.log('updateStatusDot():', checkFilter);

  statusDot.classList.toggle("active", checkFilter);
}

function addDefaultTerm(arrayOfTerms) {
  arrayOfTerms.unshift({
    name: "Any",
    slug: "",
    description: "",
    default: true,
  });
  return arrayOfTerms
}
