import { verApp, verCSS, verJS } from "../../../_dev/ar-pwa-globals.json";
import * as ar from "../ui_general/ar_globals.js";
import * as viewport from "../ui_general/viewport.js";

document.addEventListener("DOMContentLoaded", function () {
  const debugBoxHTML = `
    <div id="debug_info" class="hidden">
      <div class="label" id="debug_toggle">
        <div id="ver_app"></div>
        <div id="ver_js"></div>
        <div id="ver_css"></div>
      </div>
      <button id="reload" onclick="location.reload();">R</button>
      <div class="box">
        <ul class="data">
          <li>SPA TEMPLATE</li>
        </ul>
      </div>
      <div class="box">
        <div class="label" onclick="document.getElementById(&quot;ui_container&quot;).classList.toggle(&quot;hide&quot;)">
          UI Elements
        </div>
        <div id="ui_container" class="">
          <div class="button_wrap">
            <button-std size="micro" icon="iconamoon:close-bold">Close</button-std>
            <button-std size="small" icon="iconamoon:close-bold">Close</button-std>
            <button-std size="normal" icon="iconamoon:close-bold">Close</button-std>
            <button-std size="big" icon="" id="apply_filter" hue="100">Apply</button-std>
          </div>
          <div class="button_wrap">
            <button-std disabled="" size="micro" icon="iconamoon:close-bold"></button-std>
            <button-std disabled="" size="small" icon="iconamoon:close-bold"></button-std>
            <button-std disabled="" size="normal" icon="iconamoon:close-bold"></button-std>
            <button-std disabled="" size="big" id="x" icon="">Apply</button-std>
          </div>
          <div class="button_wrap">
            <button-std type="clear" size="normal" icon="iconamoon:close-bold">Clear</button-std>
            <button-std type="secondary" size="normal" icon="iconamoon:close-bold">Normal</button-std>
            <button-std type="primary" size="normal" icon="iconamoon:close-bold">Primary</button-std>
          </div>
          <label>
            <div class="label">Number</div><input type="number" id="test0">
          </label>
          <label>
            <div class="label">Date</div><input type="date" id="test1">
          </label>
          <label>
            <div class="label">Email</div><input type="email" id="test2">
          </label>
          <label>
            <div class="label">Password</div><input type="password" id="test3">
          </label>
          <label>
            <div class="label">Search</div><input type="search" id="test4">
          </label>
          <label>
            <div class="label">Date</div><input type="text" id="test5">
          </label>
          <label>
            <div class="label">Date</div><textarea id="test6">Textarea</textarea>
          </label>
          <label>
            <div class="label">Radio 1</div><input type="radio" name="test" id="test7">
          </label>
          <label>
            <div class="label">Radio 2</div><input type="radio" name="test" id="test8">
          </label>
          <label>
            <div class="label">Radio 3</div><input type="radio" name="test" id="test9">
          </label>
          <label>
            <div class="label">Label</div><input type="checkbox" id="test10">
          </label>
          <div class="label">Label</div>
          <select id="test11">
            <option selected="" disabled="">Select</option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </div>
      </div>
    </div>
  `;

  if (ar.settings.debugBox) {
    document.body.insertAdjacentHTML('afterbegin', debugBoxHTML);

    (function showDebugBox() {

      const debugInfoBox = document.getElementById("debug_info");

      // Collapsible
      const toggleButton = document.querySelector("#debug_info div#debug_toggle");
      toggleButton.addEventListener("click", () => {
        debugInfoBox.classList.toggle("hidden");
      });

      if (debugInfoBox) {
        const debugInfoList = document.querySelector("#debug_info ul.data");

        if (viewport.isInAppMode()) {
          // console.info("📱 viewport.isInAppMode(): true (PWA Mode)");
          debugInfoList.append("<li>App mode</li>");
        } else {
          // console.info("💻 viewport.isInAppMode(): false (Browser Mode)");
          debugInfoList.append("<li>Browser mode</li>");
        }

        // Print JS and CSS vers
        document.getElementById("ver_app").innerText = "App v" + verApp;
        document.getElementById("ver_js").innerText = "JS v" + verJS;
        document.getElementById("ver_css").innerText = "CSS v" + verCSS;

        // Draggable
        // interact(debugInfoBox).draggable({
        //   inertia: true,
        //   modifiers: [
        //     interact.modifiers.restrictRect({
        //       restriction: "parent",
        //       endOnly: true,
        //     }),
        //   ],
        //   autoScroll: true,
        //   listeners: {
        //     move: function (event) {
        //       var target = event.target;
        //       var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
        //       var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

        //       target.style.transform = "translate(" + x + "px, " + y + "px)";
        //       target.setAttribute("data-x", x);
        //       target.setAttribute("data-y", y);
        //     },
        //   },
        // });
      }
    })();
  }

});
