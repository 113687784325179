// console.log("Loaded: ar-chat-characters.js");

class ARCharacter {
  constructor(charObject) {
    (this.name = charObject.name),
      (this.ID = charObject.ID),
      (this.description = charObject.description),
      (this.icon = charObject.undefined), // icon
      (this.welcomeMessage = charObject.welcomeMessage),
      (this.descriptionLong = charObject.descriptionLong);
    (this.conversationStarters = charObject.conversationStarters),
      (this.colors = {
        hue: "",
      });
  }
}

export const ARcharacters = {
  "PNR-F1": new ARCharacter({
    name: "Perfect Nurturer Father",
    ID: "PNR-F1",
    description: "Talk with a nurturing father figure",
    icon: " ",
    welcomeMessage: "Hello, how are you today? Would you like to talk?",
    descriptionLong:
      "Get encouragement and comfort, roleplaying with an ideal father figure.",
    conversationStarters: [
      "I need to talk about something",
      "I'm upset",
      "I'm feeling good about something",
      "I need to figure something out",
    ],
    colors: {
      hue: "",
    },
  }),
  "PNR-M1": new ARCharacter({
    name: "Perfect Nurturer Mother",
    ID: "PNR-M1",
    description: "Talk with a nurturing mother figure",
    icon: " ",
    welcomeMessage: "Hello, how are you today? Would you like to talk?",
    descriptionLong:
      "Get encouragement and comfort, roleplaying with an ideal mother figure.",
    conversationStarters: [
      "I need to talk about something",
      "I'm upset",
      "I'm feeling good about something",
      "I need to figure something out",
    ],
    colors: {
      hue: "",
    },
  }),
  "PNR-Vis": new ARCharacter({
    name: "Perfect Nurturer Visualiser",
    ID: "PNR-Vis",
    description: "Create reparenting visualizations.",
    icon: " ",
    welcomeMessage:
      "Hello, I can help you create some Perfect Nurturer visualization scenes to work through whatever issue you want to work on. If you like, tell me a bit about the issue that is concerning you, and we can dialog about it and I can try to assist in coming up with an ideal visualization for it.",
    descriptionLong:
      "Create reparenting visualizations informed by Attachment Repair. Discover ideal scenes and strategies to overcome your specific issues.",
    conversationStarters: [
      "I need to talk about something",
      "Help me create a visualisation",
      "Guide me through a visualisation",
      "I need soothing",
    ],
    colors: {
      hue: "",
    },
  }),
  "RI1": new ARCharacter({
    name: "Reflective Integration Bot",
    ID: "RI1",
    description: "First test of Reflective Integration sequences",
    icon: " ",
    welcomeMessage: "Hello, how are you today? Would you like to talk?",
    descriptionLong:
      "Basic prompt",
    conversationStarters: [
      "I need to talk about something",
      "I'm upset",
      "I'm feeling good about something",
      "I need to figure something out",
    ],
    colors: {
      hue: "",
    },
  }),
  PNR1: new ARCharacter({
    name: "Perfect Nurturer Bot",
    ID: "PNR1",
    description: "Create reparenting visualizations.",
    icon: "",
    welcomeMessage:
      "Hello, I can help you create some Perfect Nurturer visualization scenes to work through whatever issue you want to work on. If you like, tell me a bit about the issue that is concerning you, and we can dialog about it and I can try to assist in coming up with an ideal visualization for it.",
    descriptionLong:
      "Create reparenting visualizations informed by Attachment Repair. Discover ideal scenes and strategies to overcome your specific issues.",
    conversationStarters: [
      "I need to talk about something",
      "Help me create a visualisation",
      "Guide me through a visualisation",
      "I need soothing",
    ],
    colors: {
      hue: "",
    },
  }),
};

/*

'Recommender': new ARCharacter(
        'Recommendation Bot',
        'RB',
        'Which AR services are right for me?',
        'https://dev5.local/wp-content/uploads/2023/12/recommender2.png',
        "Hello, I can help recommend AR meditations, courses and downloads for you, based on your other conversations, or your questions. Except <strong>I haven't been programmed yet</strong> 🤓.",
        "",
    ),
    'MentalizeBot': new ARCharacter(
        'Mentalize Bot',
        'MB',
        'Reflect on your psychology',
        'https://dev5.local/wp-content/uploads/2023/12/analyst2.png',
        "Hello, I can help you mentalize particular aspects of your psychology to help you get a better understanding of them. If you like, tell me a bit about something that's on your mind, and we can discuss it and reflect on your thought process around it. ",
        "",
    ),
    'AttachmentAnalyst': new ARCharacter(
        'Attachment Style Analyst',
        'AAA',
        'Figure out your real attachment style',
        'https://dev5.local/wp-content/uploads/2023/12/analyst1.png',
        "Hello, I'll ask you some questions and together we'll come up with a rough idea of what your attachment style might be.",
        "",
    ),
    'SecureFactors': new ARCharacter(
        'Secure Factors Facilitator',
        'SFA',
        'Troubleshoot Secure Attachment',
        'https://dev5.local/wp-content/uploads/2023/12/analyst1.png',
        "Hello :) I'll help you troubleshoot which of the factors of Secure Attachment you should prioritise working on.",
        "Learn which of the Factors of Secure Attachment you are strong and weak in.",
        ["Safety & Protection", "Attunement", "Physical affection", "Compassion & comforting", "Sense of Belonging", "Entrainment around shared experience", "Support for exploration", "Expressed delight", "Unconditional Love", "Supportive problem solving"]
    ),
*/
