import { displayJournalEntries, reconcileUserData, updateServerWithUserData } from "../journal/journal_entries";
import { icons } from "../ui_general/icons";
import { appState } from "../ui_general/ar_globals";

export async function journalInit() {
  // console.log('journalInit', 'token', appState.loginToken, 'first init', !appState.journalInit)

  // Check if we've already synced from server at app-launch to avoid doing it again
  if (appState.loginToken) {
    const journalEntries = await reconcileUserData('journal_data');
    if (journalEntries) {
      console.log('got new journal entries...', journalEntries);
      displayJournalEntries(journalEntries, previousEntriesDiv);

      appState.journalInit = true;
    }
  }
}

export const sectionJournal = document.createElement("section");
sectionJournal.setAttribute("id", "journal");
sectionJournal.classList.add("membership", "hidden");
sectionJournal.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon="${icons.postType.journal}"></iconify-icon>
        <div class='label'>Journal</div>
      </div>
    </div>

    <div class="contents">
      <p>
        Entries | Timeline
      </p>

      <div class="block" id="journal_entries">
        <div class="subheader">Entries</div>
        <p>Journal entries from meditations you've listened to.</p>
        <div id="meditation_journal_previous"></div>
      </div>

      <div class="block">
        <div class="subheader">Timeline</div>
        <p>
          Make a timeline of your trigger points and the work you are
          doing on it.
        </p>
        <p>
          Get summaries of the most salient features of your writing and
          what you are going through, to see it from a birds-eye
          perspective and know what is going on for you.
        </p>
        <p>
          Summary area for the month / quarter / year that summarises what
          you've been working on.
        </p>
        <p>Journal about your relationships and get a birds eye view.</p>
        <p>
          People with trauma overall need help creating coherent
          narratives for themselves. These summaries can help build that
          up, an externalised metacognition.
        </p>
        <p>Keyword-cloud showing the most common themes and issues.</p>
      </div>

    </div>
  </div>
`;

const previousEntriesDiv = sectionJournal.querySelector('div#meditation_journal_previous');

document.addEventListener("section_journal", () => {
  if (appState.loginToken) {
    const journalEntries = JSON.parse(localStorage.getItem("AR_Userdata_JournalEntries"));

    displayJournalEntries(journalEntries);

    if (!appState.journalInit) {
      journalInit();
    }
  }
});

document.addEventListener("loginEvent", async () => {
  console.log('journal heard login event');
  journalInit();
});

document.addEventListener("logoutEvent", () => {
  previousEntriesDiv.innerHTML = "";
});
