// Global
import "./ar_dependencies.js";

import * as ar from "./ui_general/ar_globals.js";
import { verCSS, verJS } from "./../../_dev/ar-pwa-globals.json";
import { validateLocalJWT } from "./restapi/restapi_login_user.js";

import "./app/pwa_install.js";
import "./app/service_worker_install.js";
import "./ui_general/ar_utils.js";
// Deving
import "./ui_general/debug_box.js";

// Sections
import { sectionStart } from "./sections/section_start.js";
import { sectionAssessments } from "./sections/section_assessments.js";
import { sectionChat } from "./sections/section_chat.js";
import { sectionCourses } from "./sections/section_courses.js";
import { sectionJournal, journalInit } from "./sections/section_journal.js";
import { sectionMeditation } from "./sections/section_meditation.js";
import { sectionProfile } from "./sections/section_profile.js";

// Components
import { profileMenuItems, socialMenuItems } from './ui_general/menu_list.js';
import { slidePane } from './ui_components/slidePane.js';
import { mainMenu } from "./ui_components/membership_menu.js";
import { safeArea } from "./ui_components/safearea.js";
import "./ui_components/button_std.js";
import "./ui_components/button_arrow.js";
import "./ui_components/button_view.js";
import "./ui_components/completion_bar.js";
import { setUILoggedIn, setUILoggedOut } from "./ui_components/login_form.js";
import { getTaxonomyDataFromRestApi } from "./restapi/restapi_requests.js";

if (!window.hasInitialisedPWA) {
  console.info('Vite import.meta.env', import.meta.env);

  console.info("Loaded: ⭐ ar_pwa.js", "verJS: ", verJS, " verCSS: ", verCSS, "📱 isTouchDevice(): ", ar.isTouchDevice());
  console.info("⚙️ Settings: ", ar.settings);
  // console.info("👤 isLoggedIn: ", ar.userDataObj.account.isLoggedIn);

  /**

    Init sections

  **/

  journalInit();


  /**

    Misc

  **/
  getTaxonomyDataFromRestApi();

  (function checkIfUserIsAlreadyAuthenticated() {

    const localStorageJWT = localStorage.getItem("AR_Userdata_JWT");
    // console.log('localStorageJWT', localStorageJWT);

    if (localStorageJWT) {
      setUILoggedIn();
    }

    validateLocalJWT(localStorageJWT)
      .then(tokenResponse => {
        if (tokenResponse.data?.status === 200) {
          // console.log('validateLocalJWT(): Already logged in...');

          const lsARUserAccount = JSON.parse(localStorage.getItem("AR_Userdata_Account"));
          ar.userDataObj.account = lsARUserAccount;
          ar.userDataObj.account.isLoggedIn = true;
          console.log('lsARUserAccount', lsARUserAccount);

        } else {
          // Set to no account
          setUILoggedOut();
        }
      }).catch(error => {
        console.error('validateLocalJWT(): ', error);
      });
  })();

  (function initServiceWorker() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("./service-worker.js").then(
        (registration) => {
          console.info(
            "ServiceWorker registration successful with scope:",
            registration.scope,
          );
        },
        (error) => {
          console.error("ServiceWorker registration failed:", error);
        },
      );
    }
  })();

  window.hasInitialisedPWA = true;
}

document.addEventListener("DOMContentLoaded", async function onLoad() {

  if (window.hasLoadedDOM === true) return;
  window.hasLoadedDOM = true;

  // const menu = document.querySelector("div#membership_menu");

  // Hide app loading widget
  document.getElementById("app_loading").classList.add("off");

  // insert menu here
  viewPort.append(sectionStart);
  viewPort.append(sectionProfile);
  viewPort.append(sectionAssessments);
  viewPort.append(sectionChat);
  viewPort.append(sectionCourses);
  viewPort.append(sectionJournal);
  viewPort.append(sectionMeditation);
  viewPort.append(mainMenu);
  viewPort.append(safeArea);

  // Load initial tab based on URL parameter
  (function checkAnchorAndFireTabSelector() {
    const anchor = window.location.hash.substring(1);
    if (anchor) {
      mainMenu.querySelector(`div.tab[data-membership='${anchor}']`).click();
    }
  })();

  // These need to be called independently in each section that they are used in.
  (function initButtonViewSet() {
    // console.log('button view set funct init');

    document.querySelectorAll("section.membership").forEach((section) => {
      // console.log("sections:", section);
      section.querySelectorAll("div.button_view_set").forEach((set) => {
        // Get all buttons and blocks in set
        const button_wraps = Array.from(set.children);
        const blocks = section.querySelectorAll("div.block.view_set");
        // console.log("button_wraps", button_wraps);
        // console.log("blocks", blocks);

        button_wraps.forEach((button_view) => {
          // console.log("button view", button_view);

          const button_name = button_view.attributes["data-name"].value;
          // console.log("-- name:", button_name);

          button_view.addEventListener("click", () => {
            // Toggle class ".current" to buttons
            button_wraps.forEach((button) =>
              button.classList.remove("current"),
            );
            button_view.classList.add("current");

            // Toggle class ".current" to blocks
            blocks.forEach((block) => {
              if (block.id === button_name) {
                block.classList.add("current");
              } else {
                block.classList.remove("current");
              }
            });
          });
        });
      });
    });
  })();

  (function buildSectionMenus() {
    const menuObjects = new Map([
      ['profileMenuItems', profileMenuItems],
      ['socialMenuItems', socialMenuItems],
    ]);

    document.querySelectorAll('.menu_items_container').forEach(container => {
      const menuName = container.dataset['menu'];
      const menuObj = menuObjects.get(menuName);

      container.querySelectorAll('div.list_item').forEach(listItem => {
        const menuLabel = listItem.dataset.menu;
        const menuItem = menuObj[menuLabel];
        if (menuItem) {
          if (menuItem.type === "nav") {

            listItem.addEventListener('click', () => {
              slidePane({
                element: menuItem.content,
                title: menuItem.label,
                callback: menuItem.callback,
                dataset: { menuItem: { label: menuItem.label } },
              });
            });

          } else if (menuItem.type === "link") {
            listItem.addEventListener('click', () => {
              const url = listItem.getAttribute('data-url');
              window.open(url, '_blank');
            })
          }
        }
      });
    });
  })();

});
