import { icons } from "../ui_general/icons.js";
import { profileMenuItems, socialMenuItems } from "../ui_general/menu_list.js";
import { buildSectionMenu } from "../ui_general/menus.js";
import { membershipStatus } from "../ui_components/membership_status.js";
import { userDataObj } from "../ui_general/ar_globals.js";
import { membershipPill } from "../ui_components/membership_pill.js";

// Main sectionProfile
export const sectionProfile = document.createElement("section");
sectionProfile.setAttribute("id", "profile");
sectionProfile.classList.add("membership", "hidden");
sectionProfile.innerHTML = `
  <div class="container">
    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon="${icons.profile}"></iconify-icon>
        <div class='label'>Profile</div>
      </div>
    </div>

    <div class="contents">
    </div>

  </div>`;

// Membership Status
sectionProfile.querySelector('.contents').appendChild(membershipStatus)

// Profile Menu
sectionProfile
  .querySelector('.contents')
  .appendChild(
    buildSectionMenu(
      { menuObj: profileMenuItems, name: "profileMenu" }
    )
  );

// Social Menu
sectionProfile
  .querySelector('.contents')
  .appendChild(
    buildSectionMenu(
      { menuObj: socialMenuItems, name: "socialMenu", header: "Follow" }
    )
  );

// Signout button

const logoutButton = `<button-std id="logout_submit" class="logout_submit">Sign out</button-std>`;
sectionProfile.querySelector('.contents').innerHTML += `
`;
// logoutButton.addEventListener("click", () => setUILoggedOut());

// Misc
const profileMisc = `
  <div class="container">
    <div class="header">Assessment</div>
      <div class="contents">
        <p>
          Quod aliquam libero libero laudantium vitae voluptatem. Ut
          voluptates eos eum dolores possimus quia repellendus recusandae.
          Nostrum ipsam pariatur ad et qui et culpa laboriosam.
        </p>
        <div class="block">
          <div class="subheader">Attachment &amp; Schema Assessment</div>
        </div>
        <div class="block">
          <div class="subheader">Complex Unresolved Grief</div>
        </div>
        <div class="block">
          <div class="subheader">Healthy Adult Mode Assessment</div>
        </div>
        <div class="block">
          <div class="subheader">Four poles of Mentalization</div>
        </div>
      </div>
    </div>
  </div>
  `;
// sectionProfile.innerHTML += profileMisc;

document.addEventListener("DOMContentLoaded", updateProfileMembershipWidget)
document.addEventListener('loginEvent', updateProfileMembershipWidget)
document.addEventListener('logoutEvent', updateProfileMembershipWidget);

function updateProfileMembershipWidget() {
  const newPill = membershipPill(userDataObj.account.membership_level);

  sectionProfile.querySelector('#membership_status div.name').textContent = userDataObj.account.name || "Hello";
  sectionProfile.querySelector('#membership_status div.membership_level_pill').replaceWith(newPill);
}
