export const sectionAssessments = document.createElement("section");
sectionAssessments.setAttribute("id", "assessments");
sectionAssessments.classList.add("membership", "hidden");
sectionAssessments.innerHTML = `
  <div class="container">
    <div class="section_header">
      <div class='header_wrap'>
        Assessments
      </div>
    </div>
  </div>
  `;
