import { authHeader } from "./restapi_login_user.js";
import { apiURL } from "./restapi_requests";

export async function updateUserData(jsonData) {
  // console.log('jsondata', jsonData)

  const response = await fetch(apiURL('ar', 'users/update'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": authHeader(),
    },
    body: JSON.stringify(jsonData),
    keepalive: true,
  });

  const result = await response.json();
  console.log('updateUserData() result', result);
  return result;

  // if (response.ok) {
  //   // result...

  //   return result;
  // } else {
  //   throw new Error(result.message);
  // }
};

/**

  keyNames is an array of string keynames corresponding to the ACF fields
  that store each user's data.

**/
export async function getUserData(keyArray) {

  const parameters = Object.fromEntries(keyArray.map(key => [key, true]));

  const response = await fetch(apiURL('ar', 'users/get'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": authHeader(),
    },
    body: JSON.stringify(parameters),
  });

  const result = await response.json();
  console.log('result', result);

  return result;
}
