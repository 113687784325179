import { slidePane } from "../ui_components/slidePane.js";
import { printUpgradeButton } from "../ui_components/upgrade_button.js";
import { meditationTaxonomies, enabledMeditationTaxonomies } from "../ui_general/ar_globals.js";
import { icons } from "../ui_general/icons.js";
import { meditationUserData } from "../data/ar_globaldata.js";
import "../meditation/meditationPlayer.js";
import { termSlugToName } from "../data/ar_taxonomies.js";
import { createMeditationPaneContents } from "./meditationPane.js";
import { createMenuWidget } from "../ui_components/menu_widget.js";
import { membershipPill } from "../ui_components/membership_pill.js";

export function createMeditationRow(
  {
    meditation: postData,
    page: page = "library",
    showTaxonomies = true,
    showMenu = true,
  }) {
  // console.log("createMeditationRow: postData", postData);
  // console.log('meditationUserData.data', meditationUserData.data);

  const row = createBlankRow(postData);

  window.meditationPlayerList[postData.meditationId] = postData;

  (function dataset() {
    setDatasetProperties(row, postData);
    const isFav = meditationUserData.data[postData.meditationId]?.favourite ?? false;
    row.dataset.favourite = isFav;
  })();

  if (showTaxonomies) {
    const taxonomyPills = printTaxonomyPills(row);
    if (taxonomyPills) {
      row.querySelector("div.extra_data").appendChild(taxonomyPills);
    }

    const spcPill = printSPCPill(row);
    if (spcPill) {
      row.querySelector("div.extra_data").appendChild(spcPill);
    }
  }

  if (row.dataset.canAccess === "true") {
    attachMeditationRowEventListeners({ row, page });
    if (showMenu) {
      row.querySelector('div.controls').append(createMenuWidget('meditation', postData, row, 'vertical'));
    }
  } else {
    row.querySelector('div.meditation_row_contents')
      .appendChild(printUpgradeButton(postData));
  }

  return row;
}

export function createBlankRow(postData) {
  const blankRow = document.createElement("div");
  blankRow.className = "meditation_row";

  const dateRecorded = dateformat(postData.dateRecorded, "mid")

  blankRow.innerHTML = `
    <div class='meditation_row_contents'>
     	<div class='meditation_data'>

        <div class='audioTrigger play'>
          <iconify-icon noobserver icon='${icons.lotusBold}'></iconify-icon>
        </div>

        <div class='audioTrigger pause' style='display: none'>
          <iconify-icon noobserver icon='${icons.pause}'></iconify-icon>
        </div>

        <div class='title'>${postData.title}</div>

        <div class='side_data'>
          <div class='duration'>${postData.duration} mins</div>

          <div class='controls'>
            <div class='source_spc pill' data-tippy-content='it works' style='display: none'>SPC</div>
          </div>

          <div class='journal_count' style='display: none'>
            <iconify-icon noobserver icon='${icons.journal}' class='journal'></iconify-icon>
          </div>
        </div>
      </div>

      <div class='extra_data'>
      </div>
    </div>

    <div class='row_secondary_data'>
      ${membershipPill(postData.membershipLevel).outerHTML}
      <div class='star_rating'></div>
      <div class='up_down_vote_widget'></div>
      <div class='date_recorded'>${dateRecorded}</div>
    </div>
    `;
  return blankRow;
}

export function printSPCPill(row) {
  if (row.dataset.spc_id !== undefined) {

    const pillsContainer = document.createElement('div');
    pillsContainer.classList.add('pills_container');

    const spcPill = document.createElement("div");
    spcPill.classList.add("spc_pill");
    spcPill.innerHTML = `
      <div class='label'>Source</div>
      <div class='post_type'>Self-Paced Course</div>
      <img class='spc_image' src='${row.dataset.spc_image_url_thumbnail}' />
      <div class='spc_title'>${row.dataset.spc_name}</div>
    `;
    pillsContainer.appendChild(spcPill)
    return pillsContainer;

    /*

    - [] enable this later when we can make individual calls to the REST API to get the spcPost to pass in.
    const spcId = row.dataset.postId;

    const spcPost = getSPCList(spcId) ?? restAPIRequest({spcId: spcId});

    spcPill.addEventListener("click", () => {
      linkToSPCPaneBlock(spcPost)
    });

    */
  }
}
export function printTaxonomyPills(row) {
  // Print taxonomy labels here
  const pillsContainer = document.createElement('div');
  pillsContainer.classList.add('pills_container');

  enabledMeditationTaxonomies.forEach(taxonomySlug => {
    const taxonomyNameUppercase = taxonomySlug.charAt(0).toUpperCase() + taxonomySlug.slice(1, taxonomySlug.length);
    const taxonomyData = row.dataset[`taxonomy${taxonomyNameUppercase}`];
    const taxonomyDataString = taxonomyData
      ? JSON.parse(taxonomyData)
      : []
    // console.log('taxName', taxonomySlug)
    // console.log('name:', taxonomyNameUppercase);
    // console.log(`data`, taxonomyDataString);

    const termBubbles = taxonomyDataString.map(termSlug => {
      const termName = termSlugToName(taxonomySlug, termSlug);
      return `<div class='term_bubble' data-term='${termName}'>${termName}</div>`;
    }).join("");

    if (termBubbles.length) {
      const taxonomyContainer = document.createElement("div");
      taxonomyContainer.classList.add("taxonomy_container");
      taxonomyContainer.innerHTML = `
      <div class='label'>${meditationTaxonomies[taxonomySlug].name_short}</div>
      <div class='terms_container'>${termBubbles}</div>
      `;
      pillsContainer.appendChild(taxonomyContainer);
    }
  });

  if (pillsContainer.children.length > 0) return pillsContainer;
}

export function setDatasetProperties(element, data) {
  // console.log('setDatasetProperties:', data)
  delete data.player;

  Object.entries(data).forEach(([key, value]) => {
    // Convert key from kebab-case to camelCase for dataset compatibility
    const sanitizedKey = key
      .replace(/-([a-zA-Z])/g, (match, group1) => group1.toUpperCase()); // Uppercase letters following dashes

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // If value is an object, recursively set its properties.
      // Create a container for nested data if it doesn't exist.
      const subElement = element.querySelector(`[data-id="${key}"]`) || element;
      setDatasetProperties(subElement, value);
    } else {
      // Ensure the value is converted to string
      const stringValue = String(value);
      // Assign the value to the dataset
      element.dataset[sanitizedKey] = stringValue;
    }
  });
}

function attachMeditationRowEventListeners({ row, page }) {
  const state = {
    title: row.dataset.title,
    audioUrl: row.dataset.audioUrl,
    meditationId: row.dataset.meditationId,
  };
  // Put meditation data such as the src url into a global meditation player object
  // that the web component can access.


  // spawn a pane
  row.addEventListener("click", () => {

    slidePane({
      direction: "vertical",
      element: createMeditationPaneContents(row),
      title: row.dataset.title,
      dataset: row.dataset,
      target: row,
    });

  })
}

window.createMeditationPlayer = function (meditationId) {
  // console.log('window.createMeditationPlayer()', meditationId)
  const player = document.createElement('meditation-player');
  const meditationData = window.meditationPlayerList[meditationId];
  player.initialize(meditationData);

  return player;
}
