import { appState, userDataObj } from "../ui_general/ar_globals";
import { logTheUserIn } from "../restapi/restapi_login_user.js";
import { icons } from "../ui_general/icons";
import { localStorageFieldsUserData } from "../data/ar_localstorage.js";

// Components
// Button for Login
export const loginButton = document.createElement('button-std');
loginButton.setAttribute("id", "login_submit");
loginButton.setAttribute("hue", 210);
loginButton.setAttribute('icon', icons.login);
loginButton.textContent = "Login";

// Input for Email
export const emailForm = document.createElement('input');
emailForm.setAttribute("type", "text");
emailForm.setAttribute("id", "login_email");
emailForm.setAttribute("placeholder", "Username or email");
emailForm.setAttribute("autocomplete", "email");

// Div for Logged In User's Name
export const loggedUserName = document.createElement('div');
loggedUserName.setAttribute("id", "logged_user_name");
loggedUserName.classList.add('global_hidden');

// Input for Password
export const passwordForm = document.createElement('input');
passwordForm.setAttribute("type", "password");
passwordForm.setAttribute("id", "login_password");
passwordForm.setAttribute("placeholder", "Password");
passwordForm.setAttribute("autocomplete", "current-password")

// Button for Logout
export const logoutButton = document.createElement('button-std');
logoutButton.setAttribute("id", "logout_submit");
logoutButton.setAttribute('icon', icons.logout);
logoutButton.classList.add('global_hidden');
logoutButton.textContent = "Logout";

export const loginStatusText = document.createElement('div');
loginStatusText.setAttribute('id', 'login_status_text');

// Form to hold it all together
export const loginForm = document.createElement('div');
loginForm.setAttribute("id", "login_box");
loginForm.classList.add('active');
loginForm.append(
  loggedUserName,
  emailForm,
  passwordForm,
  loginButton,
  logoutButton,
  loginStatusText)

// Functionality
loginButton.addEventListener("keypress", (event) => keyEnterLogin(event))

loginButton.addEventListener("click", () => {
  document.querySelector("div#login_status_text").innerText = "Logging in...";

  logTheUserIn(emailForm.value, passwordForm.value)
    .then(tokenResponse => {
      if (tokenResponse.data.status === 200) {
        // console.log('logTheUserIn() tokenResponse:', tokenResponse)
      } else {
        // console.log('Not logged in');
        // Set default level
      }
    }).finally(() => {
      //
    }).catch(error => {
      console.error('logTheUserIn(): ', error);
      loginStatusText.innerText = "Log in Failed via Form";
    });
});

export function keyEnterLogin(event) {
  if (event.key == "Enter") {
    // console.log('Enter: login form')
    loginButton.click();
  }
}

emailForm.addEventListener("keypress", (event) => keyEnterLogin(event))

passwordForm.addEventListener("keypress", (event) => keyEnterLogin(event))

logoutButton.addEventListener("click", () => setUILoggedOut());

document.addEventListener('loginEvent', function (loginEvent) {
  // console.log('restapi_login_user.js heard a loginEvent')
  const { isLoggedIn } = loginEvent.detail;
  if (isLoggedIn) {
    // console.log("👤 ✅ User is logged in");
    setUILoggedIn();

  } else {
    console.log("👤 ❌ User is not logged in");
    // Handle not logged in scenario
  }
});

// UI STUFF

export function setUILoggedIn() {
  appState.loginToken = true;
  const userName = JSON.parse(localStorage.getItem("AR_Userdata_Account")).name;
  // [] put a membership account pill here

  // Clear values
  emailForm.value = "";
  passwordForm.value = "";
  loginStatusText.innerText = "";

  // Hide
  emailForm.classList.add("global_hidden");
  passwordForm.classList.add("global_hidden");
  loginButton.classList.add("global_hidden");

  // Show
  logoutButton.classList.remove("global_hidden");
  loggedUserName.classList.remove("global_hidden");
  loggedUserName.innerText = `Welcome, ${userName}`;
}

export function setUILoggedOut() {
  appState.loginToken = false;

  userDataObj.account = {
    membership_tier: {
      value: "free_tier",
      label: "No Account",
    },
    isLoggedIn: false,
  }
  // console.log('setUILoggedOut()', userDataObj);

  document.body.dataset["loggedIn"] = "false";

  // Hide
  logoutButton.classList.add("global_hidden");
  loggedUserName.classList.add("global_hidden");

  // Show
  emailForm.classList.remove("global_hidden")
  loginButton.classList.remove("global_hidden");
  passwordForm.classList.remove("global_hidden");

  // Remove all LocalStorage items
  localStorageFieldsUserData.forEach(fieldName => {
    localStorage.removeItem(fieldName);
  })

  // Dispatch logoutEvent
  const logOutEvent = new CustomEvent("logoutEvent", {
    bubbles: true,
    composed: true,
    detail: {}
  });

  document.dispatchEvent(logOutEvent);
}
